import React, {useEffect} from 'react';
import PlayerProfile from "./PlayerProfile";
import styled from "styled-components";
import PlayerDetailSavantBar from "./statbar/PlayerDetailSavantBar";
import TotalStatComponent from "./total/TotalStatComponent";
import usePlayerDetailQuery from "../../hooks/detail/usePlayerDetailQuery";
import {useLoading} from "../../context/loading/LoadingContext";
import useNavigateToCompare from "../../hooks/compare/useNavagateToCompare";

const PlayerDetail = () => {
  const { data: detail, error, isLoading, playerId } = usePlayerDetailQuery();
  const { setLoading } = useLoading();
  const { handleCompareClick } = useNavigateToCompare();

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  if (error) {
    return <div>{error.message}</div>;
  }

  if (!detail) {
    return <div>No player details available</div>;
  }

  return (
      <PlayerDetailContainer>
        <PlayerProfile player={detail}/>
        <PlayerDetailSavantBar
            position={detail.position}
            hitters={detail.hitterStats}
            pitchers={detail.pitcherStats}
            onCompareClick={() => handleCompareClick(playerId, detail.position)}
        />
        <TotalStatComponent detail={detail}/>
      </PlayerDetailContainer>
  );
};

export default PlayerDetail;


const PlayerDetailContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  text-align: center;
  height: auto;
  padding: 20px;
`;
