import {client} from "./ApiClient";

export interface GlossaryRequest {
  name: string;
  description: string;
}


export interface GlossaryOrderUpdateRequest {
  glossaryIds: number[];
}


export interface GlossaryResponse {
  id: number;
  name: string;
  description: string;
}

export const getAllGlossaries = async (): Promise<GlossaryResponse[]> => {
  let response = await client.get<GlossaryResponse[]>('/admin/glossaries');
  return response.data;
};

export const addGlossary = async (request: GlossaryRequest): Promise<void> => {
  await client.post('/admin/glossaries', request);
};

export const updateGlossary = async (glossaryId: number, request: GlossaryRequest): Promise<void> => {
  await client.put(`/admin/glossaries/${glossaryId}`, request);
};

export const updateGlossaryOrder = async (request: GlossaryOrderUpdateRequest): Promise<void> => {
  await client.post(`/admin/glossaries/order`, request);
};

export const deleteGlossary = async (glossaryId: number): Promise<void> => {
  await client.delete(`/admin/glossaries/${glossaryId}`);
};
