import {client} from "./ApiClient";

export class AnnouncementApi {
  static async getAnnouncements(): Promise<AnnouncementResponse[]> {
    let response = await client.get<AnnouncementResponse[]>(`/announcements`);
    return response.data
  }
}

export interface AnnouncementResponse {
  content: string;
  announcementDateTime: Date
}
