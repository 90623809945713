import {useMutation, useQuery, useQueryClient} from "react-query";
import {useState} from "react";
import {
  addAnnouncement,
  AnnouncementAdminResponse,
  AnnouncementRequest,
  deleteAnnouncement,
  getAnnouncements,
  updateAnnouncement
} from "../../apis/AnnouncementAdminApi";

export const useAnnouncementAdmin = () => {
  const {announcements, error, isLoading} = useFetchAnnouncements();
  const {addAnnouncement} = useAddAnnouncement();
  const {updateAnnouncement} = useUpdateAnnouncement();
  const {deleteAnnouncement} = useDeleteAnnouncement();

  const [newAnnouncement, setNewAnnouncement] = useState<AnnouncementRequest>({content: ""});

  const handleAddAnnouncement = () => {
    addAnnouncement(newAnnouncement);
    setNewAnnouncement({content: ""});
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {name, value} = e.target;
    setNewAnnouncement({...newAnnouncement, [name]: value});
  };

  return {
    announcements,
    error,
    isLoading,
    newAnnouncement: newAnnouncement,
    handleInputChange,
    handleAddAnnouncement: handleAddAnnouncement,
    updateAnnouncement,
    deleteAnnouncement,
  };
};

const useFetchAnnouncements = () => {
  const {data, error, isLoading, ...rest} = useQuery<AnnouncementAdminResponse[], Error>({
    queryKey: ['Announcements'],
    queryFn: getAnnouncements,
    staleTime: 10000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return {announcements: data, error, isLoading, ...rest};
};

const useAddAnnouncement = () => {
  const queryClient = useQueryClient();
  const {mutate, ...rest} = useMutation({
    mutationFn: (newAnnouncement: AnnouncementRequest) => addAnnouncement(newAnnouncement),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['Announcements']});
      alert('도움말 생성 성공!');
    },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.msg || '도움말 생성에 실패했습니다.';
      alert(errorMessage);
    },
  });

  return {addAnnouncement: mutate, ...rest};
};

const useUpdateAnnouncement = () => {
  const queryClient = useQueryClient();
  const {mutate, ...rest} = useMutation({
    mutationFn: ({id, announcement}: { id: number, announcement: AnnouncementRequest }) =>
        updateAnnouncement(id, announcement),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['Announcements']});
      alert('공지 수정 성공!');
    },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.msg || '공지 수정에 실패했습니다.';
      alert(errorMessage);
    },
  });

  return {updateAnnouncement: mutate, ...rest};
};

const useDeleteAnnouncement = () => {
  const queryClient = useQueryClient();
  const {mutate, ...rest} = useMutation({
    mutationFn: (id: number) => deleteAnnouncement(id),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['Announcements']});
      alert('삭제 완료');
    },
    onError: () => {
      alert('Failed to delete glossary.');
    },
  });

  return {deleteAnnouncement: mutate, ...rest};
};
