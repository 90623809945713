import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {PATH} from "../../../constant/Path";

const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 150px;
    width: 100px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none; /* 기본 링크 스타일 제거 */
`;

const StyledDiv = styled.div`
  font-family: 'Jua', sans-serif;
  font-size: 28px;
  color: #333;
`;

class Logo extends React.Component {
  render() {
    return (
      <LogoContainer>
        <StyledLink to={PATH.HOME}>
          <StyledDiv>KBO INSIGHT</StyledDiv>
        </StyledLink>
      </LogoContainer>
    );
  }
}

export default Logo;
