import {client} from "./ApiClient";

export class GlossaryApi {
  static async getGlossaries(): Promise<GlossaryResponse[]> {
    let response = await client.get<GlossaryResponse[]>(`/glossaries`);
    return response.data
  }
}

export interface GlossaryResponse {
  id: number;
  name: string;
  description: string;
}
