
export const XWOBA_DESCRIPTION = "기대 가중 출루율 (타격 종합 스탯)"
export const XWOBA_CON_DESCRIPTION = "컨택 시 기대 wOBA"
export const EV_DESCRIPTION = "평균 타구속도"
export const BARREL_DESCRIPTION = "Barrel (가장 생산성이 좋은 타구) 비율"
export const BARREL_PA_DESCRIPTION = "Barrel 타구, 분모 타석 기반"
export const HARDHIT_DESCRIPTION = "강한 타구 비율"
export const ICR_DESCRIPTION = "컨택시 안타가 될 확률이 높은 타구 비율"
export const IPA_DESCRIPTION = "타석당 안타가 될 확률이 높은 타구 비율"
export const CHASE_DESCRIPTION = "볼에 배트가 나가는 비율"
export const SIERA_DESCRIPTION = "타구 성분 비율과 이벤트로 예측한 기대 방어율"
export const SD_LA_DESCRIPTION = "타구의 발사각이 일관됐는지 나타내는 지표"
export const ZONE_JUDGEMENT_DESCRIPTION = "타자가 볼과 스트라이크를 구분하여 스윙하는 능력"
export const XERA_DESCRIPTION = "xwOBA를 ERA 스케일로 바꾼 수치"
export const WHIFF_DESCRIPTION = "스윙 대비 헛스윙률"
export const SWSP_DESCRIPTION = "유의미한 발사각 (8 ~ 32도) 사이의 타구 비율"
export const XBA_DESCRIPTION = "기대타율"
export const CSW_DESCRIPTION = "Called Strikes + Whiff %(유의미한 스트라이크 비율)"
