import React, {useState} from "react";
import {GlossaryRequest, GlossaryResponse} from "../../apis/GlossaryAdminApi";
import EditableItem from "../../styles/EditableItem";
import EditableField from "../../styles/EditableField";
import TextDisplay from "../../styles/TextDisplay";

interface EditableGlossaryProps {
  glossary: GlossaryResponse;
  updateGlossary: (data: { id: number; glossary: GlossaryRequest }) => void;
  deleteGlossary: (id: number) => void;
}

const EditableGlossaryItem = ({ glossary, updateGlossary, deleteGlossary }: EditableGlossaryProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedGlossary, setEditedGlossary] = useState<GlossaryRequest>({
    name: glossary.name,
    description: glossary.description,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setEditedGlossary({ ...editedGlossary, [name]: value });
  };

  const handleSave = () => {
    updateGlossary({ id: glossary.id, glossary: editedGlossary });
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedGlossary({ name: glossary.name, description: glossary.description });
    setIsEditing(false);
  };

  return (
      <EditableItem
          isEditing={isEditing}
          onSave={handleSave}
          onCancel={handleCancel}
          onDelete={() => deleteGlossary(glossary.id)}
          toggleEdit={() => setIsEditing(true)}
      >
        {isEditing ? (
            <>
              <EditableField label="제목" name="name" value={editedGlossary.name} onChange={handleInputChange}/>
              <EditableField label="설명" name="description" value={editedGlossary.description} onChange={handleInputChange} textarea/>
            </>
        ) : (
            <>
              <h2>{glossary.name}</h2>
              <TextDisplay text={glossary.description}/>
            </>
        )}
      </EditableItem>
  );
};

export default EditableGlossaryItem;
