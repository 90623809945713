import {useEffect, useRef} from 'react';
import {useQuery} from 'react-query';
import useYear from "./useYear";
import useSort from "../sort/useSort";
import usePage from "./usePage";
import {useLoading} from "../../context/loading/LoadingContext";

interface ApiFunc<T> {
  (year: number): Promise<T[]>;
}

interface FilterHook<T, C> {
  (stats: T[], initialCriteria: C): {
    filteredStats: T[];
    filterCriteria: C;
    handleFilterChange: (newCriteria: Partial<C>) => void;
  };
}

const useStats = <T, C>(
  apiFunc: ApiFunc<T>,
  queryKey: string,
  useFilterHook: FilterHook<T, C>,
  initialFilterCriteria: C,
  initialSortField: keyof T,
) => {
  const { year, setYear } = useYear();
  const { setLoading } = useLoading();

  const { data, error, isLoading } = useQuery([queryKey, year], () => apiFunc(year), {
    staleTime: 10 * 60 * 1000,
    cacheTime: 10 * 60 * 1000
  });

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  const stats = data || [];
  const { filteredStats, filterCriteria, handleFilterChange } = useFilterHook(stats, initialFilterCriteria);
  const { sortedStats, statSort } = useSort<T>(filteredStats, initialSortField);
  const { page, setCurrentPage, pageSize } = usePage(sortedStats);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      setCurrentPage(1);
    }
  }, [year, filterCriteria, statSort.sortField, statSort.sortOrder, setCurrentPage]);

  const startIndex = (page.currentPage - 1) * pageSize;
  const currentStats = sortedStats.slice(startIndex, startIndex + pageSize);

  return {
    stats: currentStats,
    error: error ? '데이터를 가져오는 중 에러가 발생했습니다.' : null,
    isLoading,
    page,
    handleFilterChange,
    filterCriteria,
    year,
    setYear,
    statSort,
    pageSize,
  };
};

export default useStats;
