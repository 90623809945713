import {useEffect, useState} from 'react';
import {PlayerSearchRes} from '../../type/res/PlayerSearchRes';
import {PlayerSearchApi} from '../../apis/PlayerSearchApi';
import {debounce} from 'lodash'

const useSearch = (initialKeyword = '') => {
  const [keyword, setKeyword] = useState<string>(initialKeyword);
  const [players, setPlayers] = useState<PlayerSearchRes[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchPlayers = debounce((searchTerm: string) => {
      if (searchTerm.trim().length >= 2) {
        setLoading(true);
        setError(null);

        PlayerSearchApi.getPlayers(searchTerm)
          .then(result => {
            setPlayers(result);
          })
          .catch(err => {
            setError(err);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setPlayers([]);
      }
    }, 300);

    fetchPlayers(keyword);

    // clean up the debounce function
    return () => {
      fetchPlayers.cancel();
    };
  }, [keyword]);

  return { keyword, setKeyword, players, loading, error };
};

export default useSearch;
