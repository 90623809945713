import styled from "styled-components";
import React from "react";
import {PlayerSearchRes} from "../../../type/res/PlayerSearchRes";
import {PATH} from "../../../constant/Path";
import {Link} from "react-router-dom";
import {CustomDiv} from "../../../styles/Table";

interface PlayerListProps {
  $show: boolean;
}

const PlayerList = styled.ul<PlayerListProps>`
  position: absolute;
  top: calc(80%);
  list-style: none;
  padding: 0;
  margin: 5px 0;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 2px;
  background: white;
  position: absolute;
  width: 100%;
  z-index: 1000;
  display: ${(props) => (props.$show ? 'block' : 'none')};
`;

const PlayerItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  padding: 4px;
  font-size: 18px;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }

  cursor: pointer;
`;

const PlayerName = styled.span`
  font-weight: bold;
`;

const PlayerInfo = styled.span`
  color: gray;
  font-size: 14px;
`;

const PlayerLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-decoration: none;
  color: inherit;
`;


interface PlayerSearchListProps {
  isFocused: boolean;
  players: PlayerSearchRes[];
}

const PlayerSearchList = ({isFocused, players}: PlayerSearchListProps) => {
  if (players.length === 0) {
    return (
        <PlayerList $show={isFocused}>
          <CustomDiv>세부 데이터가 등록된 선수만 검색 가능합니다.</CustomDiv>
        </PlayerList>
    );
  }

  return (
      <PlayerList $show={isFocused}>
        {players.map((player) => (
            <PlayerItem key={player.playerId}>
              <PlayerLink to={PATH.PLAYER_DETAIL.replace(':playerId', player.playerId.toString())}>
                <PlayerName>{player.name}</PlayerName>
                <PlayerInfo>
                  {player.team} | {player.position} | {player.handInfo}
                </PlayerInfo>
              </PlayerLink>
            </PlayerItem>
        ))}
      </PlayerList>
  );
};

export default PlayerSearchList;
