import React from "react";
import {PitcherDetailRes} from "../../../type/res/PlayerDetailRes";
import {DetailTd, DetailTh} from "../../../styles/Table";
import {Table, TableContainer} from "./TotalStatComponent";
import {getBackgroundColor} from "./functions";

interface PitcherDetailStatComponentProps {
  stats: PitcherDetailRes[];
}

const PitcherTotalStatComponent = ({stats}: PitcherDetailStatComponentProps) => {
  const sortedStats = [...stats].sort((a, b) => a.year - b.year);

  return (
      <TableContainer>
        <Table>
          <thead>
          <tr>
            <DetailTh>연도</DetailTh>
            <DetailTh>팀</DetailTh>
            <DetailTh>나이</DetailTh>
            <DetailTh>이닝</DetailTh>
            <DetailTh>ERA</DetailTh>
            <DetailTh>FIP</DetailTh>
            <DetailTh>RA9</DetailTh>
            <DetailTh>xERA</DetailTh>
            <DetailTh>SIERA</DetailTh>
            <DetailTh>Whiff %</DetailTh>
            <DetailTh>Chase %</DetailTh>
            <DetailTh>EV</DetailTh>
            <DetailTh>HardHit %</DetailTh>
            <DetailTh>Barrel %</DetailTh>
            <DetailTh>Barrel/PA</DetailTh>
            <DetailTh>IPA %</DetailTh>
            <DetailTh>ICR %</DetailTh>
            <DetailTh>xwOBAcon</DetailTh>
            <DetailTh>xwOBA</DetailTh>
            <DetailTh>CSW %</DetailTh>
          </tr>
          </thead>
          <tbody>
          {sortedStats.map((stat, index) => {
            const {rank, ...rest} = stat;
            return (
                <tr key={index}>
                  <DetailTd $bgColor="">{rest.year}</DetailTd>
                  <DetailTd $bgColor="">{rest.team}</DetailTd>
                  <DetailTd $bgColor="">{rest.age}</DetailTd>
                  <DetailTd $bgColor="">{rest.innings}</DetailTd>
                  <DetailTd $bgColor="">{rest.era?.toFixed(2)}</DetailTd>
                  <DetailTd $bgColor={getBackgroundColor(rank?.fip)}>{rest.fip?.toFixed(2)}</DetailTd>
                  <DetailTd $bgColor="">{rest.ra9?.toFixed(2)}</DetailTd>
                  <DetailTd $bgColor={getBackgroundColor(rank?.expectedEra)}>{rest.expectedEra?.toFixed(2)}</DetailTd>
                  <DetailTd $bgColor={getBackgroundColor(rank?.siera)}>{rest.siera?.toFixed(2)}</DetailTd>
                  <DetailTd $bgColor={getBackgroundColor(rank?.whiffPercent)}>{rest.whiffPercent?.toFixed(1)}</DetailTd>
                  <DetailTd $bgColor={getBackgroundColor(rank?.chasePercent)}>{rest.chasePercent?.toFixed(1)}</DetailTd>
                  <DetailTd $bgColor={getBackgroundColor(rank?.exitVelocity)}>{rest.exitVelocity?.toFixed(1)}</DetailTd>
                  <DetailTd $bgColor={getBackgroundColor(rank?.hardHitPercent)}>{rest.hardHitPercent?.toFixed(1)}</DetailTd>
                  <DetailTd $bgColor={getBackgroundColor(rank?.barrelPercent)}>{rest.barrelPercent?.toFixed(1)}</DetailTd>
                  <DetailTd $bgColor={getBackgroundColor(rank?.barrelByPaPercent)}>{rest.barrelByPaPercent?.toFixed(1)}</DetailTd>
                  <DetailTd $bgColor={getBackgroundColor(rank?.icrPercent)}>{rest.icrPercent?.toFixed(1)}</DetailTd>
                  <DetailTd $bgColor={getBackgroundColor(rank?.ipaPercent)}>{rest.ipaPercent?.toFixed(1)}</DetailTd>
                  <DetailTd $bgColor={getBackgroundColor(rank?.expectedWobaCon)}>{rest.expectedWobaCon?.toFixed(3)}</DetailTd>
                  <DetailTd $bgColor={getBackgroundColor(rank?.expectedWoba)}>{rest.expectedWoba?.toFixed(3)}</DetailTd>
                  <DetailTd $bgColor={getBackgroundColor(rank?.callStrikePercent)}>{rest.callStrikePercent?.toFixed(1)}</DetailTd>
                </tr>
            );
          })}
          </tbody>
        </Table>
      </TableContainer>
  );
};

export default PitcherTotalStatComponent;
