import React from 'react';
import {PitcherDetailRes} from "../../../type/res/PlayerDetailRes";
import {PlayerStatsContainer, Section} from "./HitterStatsBar";
import StatRowItem from "./StatRowItem";
import RankSampleLowMessage from "./RankSampleLowMessage";
import {
  BARREL_DESCRIPTION,
  BARREL_PA_DESCRIPTION,
  CHASE_DESCRIPTION,
  CSW_DESCRIPTION,
  EV_DESCRIPTION,
  HARDHIT_DESCRIPTION,
  ICR_DESCRIPTION,
  IPA_DESCRIPTION,
  SIERA_DESCRIPTION,
  WHIFF_DESCRIPTION,
  XERA_DESCRIPTION,
  XWOBA_CON_DESCRIPTION
} from "./StatDescriptionConst";

interface PitcherStatBarProps {
  player: PitcherDetailRes;
}

const PitcherStatsBar = ({player} : PitcherStatBarProps) => {
  return (
    <PlayerStatsContainer>
      <Section>
        <RankSampleLowMessage condition={player.rank == null} message="샘플이 부족합니다"/>
        <StatRowItem
            label={"xERA"}
            rank={player.rank?.expectedEra}
            value={player.expectedEra?.toFixed(2)}
            description={XERA_DESCRIPTION}
        />
        <StatRowItem
            label={"SIERA"}
            rank={player.rank?.siera}
            value={player.siera?.toFixed(2)}
            description={SIERA_DESCRIPTION}
        />
        <StatRowItem
            label={"FIP"}
            rank={player.rank?.fip}
            value={player.fip?.toFixed(2)}
        />
        <StatRowItem
            label={"xwOBAcon"}
            rank={player.rank?.expectedWobaCon}
            value={player.expectedWobaCon?.toFixed(3)}
            description={XWOBA_CON_DESCRIPTION}
        />
        <StatRowItem
            label={"Whiff %"}
            rank={player.rank?.whiffPercent}
            value={`${player.whiffPercent?.toFixed(1)}%`}
            description={WHIFF_DESCRIPTION}
        />
        <StatRowItem
            label={"Chase %"}
            rank={player.rank?.chasePercent}
            value={`${player.chasePercent?.toFixed(1)}%`}
            description={CHASE_DESCRIPTION}
        />
        <StatRowItem
            label={"CSW %"}
            rank={player.rank?.callStrikePercent}
            value={`${player.callStrikePercent?.toFixed(1)}%`}
            description={CSW_DESCRIPTION}
        />
        <StatRowItem
            label={"EV"}
            rank={player.rank?.exitVelocity}
            value={player.exitVelocity?.toFixed(1)}
            description={EV_DESCRIPTION}
        />
        <StatRowItem
            label={"Barrel %"}
            rank={player.rank?.barrelPercent}
            value={`${player.barrelPercent?.toFixed(1)}%`}
            description={BARREL_DESCRIPTION}
        />
        <StatRowItem
            label={"Barrel/PA"}
            rank={player.rank?.barrelByPaPercent}
            value={`${player.barrelByPaPercent?.toFixed(1)}%`}
            description={BARREL_PA_DESCRIPTION}
        />
        <StatRowItem
            label={"IPA %"}
            rank={player.rank?.ipaPercent}
            value={`${player.ipaPercent?.toFixed(1)}%`}
            description={IPA_DESCRIPTION}
        />
        <StatRowItem
            label={"ICR %"}
            rank={player.rank?.icrPercent}
            value={`${player.icrPercent?.toFixed(1)}%`}
            description={ICR_DESCRIPTION}
        />
        <StatRowItem
            label={"HardHit %"}
            rank={player.rank?.hardHitPercent}
            value={`${player.hardHitPercent?.toFixed(1)}%`}
            description={HARDHIT_DESCRIPTION}
        />
      </Section>
    </PlayerStatsContainer>
  );
};


export default PitcherStatsBar;

