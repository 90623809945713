import styled from 'styled-components';

export const FilterContainer = styled.div`
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
    max-width: 800px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: stretch;
    }
`;

export const FilterSelect = styled.select`
  color: transparent;
  padding: 8px 50px;
  font-size: 14px;
  border: 1px solid #2a1313;
  background-color: #ffffff;
  border-radius: 4px;
  appearance: none;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="12" viewBox="0 0 24 24" width="12" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-position: right 0px center;
  background-size: 16px;
  flex-grow: 1;
  max-width: 100%;
  transition: border-color 0.3s, box-shadow 0.3s;

  @media (max-width: 768px) {
    width: 100%;
  }

  &:focus {
    border-color: #999;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    outline: none;
  }

  & option {
    color: #333;
    background-color: #fff;
    padding: 10px;
  }
`;
