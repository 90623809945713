
const profile: { [key: string]: string } = {
  KIA: 'KIA 타이거즈',
  롯데: '롯데 자이언츠',
  NC: 'NC 다이노스',
  한화: '한화 이글스',
  키움: '키움 히어로즈',
  삼성: '삼성 라이온즈',
  두산: '두산 베어스',
  KT: 'KT 위즈',
  SSG: 'SSG 랜더스',
  LG: 'LG 트윈스',
};

export const getFullTeamName = (abbreviation: string): string => {
  return profile[abbreviation] || abbreviation;
};


const teamColors: { [key: string]: string } = {
  KIA: '#EA0029',
  롯데: '#041E42',
  NC: '#315288',
  한화: '#FF6600',
  키움: '#570514',
  삼성: '#074CA1',
  두산: '#131230',
  KT: '#000000',
  SSG: '#CE0E2D',
  LG: '#C30452',
};

export const getTeamColor = (team: string): string => {
  return teamColors[team] || '#ffffff'; // 기본 색상 설정
};