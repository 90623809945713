import styled, {css} from "styled-components";

export const commonTd = css`
    padding: 6px;
    text-align: right;
    border: 1px solid #ddd;
    white-space: nowrap;
    font-size: 16px;
    font-family: hanna;

    @media (max-width: 768px) {
        padding: 4px;
        font-size: 12px;
    }
`;

export const pageTd = css`
    ${commonTd}
    &:nth-child(2) {
        position: sticky;
        left: 0;
        background-color: #ffffff;
    }
`;

export const DetailTd = styled.td<{ $bgColor: string }>`
    ${commonTd}
    background-color: ${({ $bgColor }) => $bgColor};

    &:nth-child(1) {
        position: sticky;
        left: 0;
        background-color: #ffffff;
    }
`;
export const commonTh = css`
    padding: 2px 6px 10px;
    text-align: right;
    white-space: nowrap;
    font-weight: bold;
    font-family: jua;
    cursor: pointer;
    font-size: 16px;
    background-color: #ffffff;

    border-bottom: 2px solid black;
    line-height: 1.2;

    @media (max-width: 768px) {
        padding: 4px;
        font-size: 12px;
    }
`;

export const pageTh = css`
    ${commonTh}
    &:nth-child(2) {
        position: sticky;
        left: 0;
    }
`;

export const DetailTh = styled.td`
  ${commonTh}
  &:nth-child(1) {
      position: sticky;
      left: 0;
  }
`;


export const CustomDiv = styled.div`
    font-family: jua;

`;
