import {useEffect, useRef, useState} from 'react';
import {useMultipleQueryStrings} from "../common/useMultipleQueryString";

export interface StatSort<T> {
  sortField: keyof T;
  sortOrder: 'asc' | 'desc';
  handleSortChange: (field: keyof T) => void;
}

const useSort = <T,>(
  stats: T[],
  initialSortField: keyof T,
  initialSortOrder: 'asc' | 'desc' = 'desc'
) => {
  const [queryValues, handleValuesChange] = useMultipleQueryStrings({
    sortField: initialSortField as string,
    sortOrder: initialSortOrder,
  });

  const [sortedStats, setSortedStats] = useState<T[]>([]);
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    const parsedSortField = queryValues.sortField as keyof T;
    const sorted = [...stats].sort((a, b) => {
      if (queryValues.sortOrder === 'asc') {
        return a[parsedSortField] > b[parsedSortField] ? 1 : -1;
      } else {
        return a[parsedSortField] < b[parsedSortField] ? 1 : -1;
      }
    });

    setSortedStats(sorted);
  }, [stats, queryValues.sortField, queryValues.sortOrder]);

  const handleSortChange = (field: keyof T) => {
    if (queryValues.sortField === field) {
      handleValuesChange({
        sortField: field as string,
        sortOrder: queryValues.sortOrder === 'asc' ? 'desc' : 'asc',
      });
    } else {
      handleValuesChange({
        sortField: field as string,
        sortOrder: 'desc',
      });
    }
  };

  return {
    sortedStats,
    statSort: {
      sortField: queryValues.sortField as keyof T,
      sortOrder: queryValues.sortOrder,
      handleSortChange,
    } as StatSort<T>,
  };
};

export default useSort;
