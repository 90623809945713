import React from 'react';
import styled from 'styled-components';

interface CompareSearchBarProps {
  keyword: string;
  setKeyword: (keyword: string) => void;
  onFocus: () => void,
  onBlur: () => void
}

const CompareSearchBar = ({ keyword, setKeyword, onFocus, onBlur}: CompareSearchBarProps) => {

  return (
      <SearchBarContainer>
        <SearchInput
            type="text"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={`검색하기`}
        />
      </SearchBarContainer>
  );
};

export default CompareSearchBar;

const SearchBarContainer = styled.div`
  position: relative; 
  z-index: 1000;
  width: 100%;
  display: flex; /* Flexbox를 사용하여 자식 요소를 중앙에 배치 */
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center; /* 수직 중앙 정렬 */
`;


const SearchInput = styled.input`
  width: 100%;
  font-family: jua;
  padding: 1rem;
  font-size: 1rem;
  border: 2px solid #ccc; /* border-width를 고정 */
  border-radius: 0.25rem;
  box-sizing: border-box; /* 패딩과 보더를 크기에 포함시킴 */
  outline: none; /* 포커스 시 기본 아웃라인 제거 */
  transition: border-color 0.2s ease-in-out; /* 색상 변경 시 부드러운 전환 */

  &:focus {
    border-color: #007bff;
  }
`;

