export const TEAM_FILTERS = [
  { value: "", label: "전체" },
  { value: "두산", label: "두산" },
  { value: "NC", label: "NC" },
  { value: "SSG", label: "SSG" },
  { value: "KT", label: "KT" },
  { value: "한화", label: "한화" },
  { value: "롯데", label: "롯데" },
  { value: "KIA", label: "KIA" },
  { value: "키움", label: "키움" },
  { value: "삼성", label: "삼성" },
  { value: "LG", label: "LG" }
];

export const PA_OPTIONS = [0, 10, 30, 50, 100, 150, 200, 250, 300, 350, 400, 450];

export const INNINGS_OPTIONS = [0, 10, 30, 50, 75, 100, 120, 144];

export const POSITION_OPTIONS = [
  { value : "타자", label: "타자"},
  { value : "투수", label: "투수"},
];
