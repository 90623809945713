export const POSITIONS = {
  PITCHER: '투수',
  HITTER: '타자',
  CATCHER: '포수',
  INFIELDER: '내야수',
  OUTFIELDER: '외야수',
  DESIGNATED_HITTER: '지명타자',
} as const;

export const POSITION_OPTIONS = [
  { label: '투수', value: POSITIONS.PITCHER },
  { label: '타자', value: POSITIONS.HITTER },
];

export type PositionType = typeof POSITIONS[keyof typeof POSITIONS];
