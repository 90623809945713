import {useRef, useState} from "react";

export const useDragAndDrop = <T extends any>(items: T[]) => {
  const [order, setOrder] = useState<T[]>(items);
  const dragItem = useRef<number | null>(null);
  const dragOverItem = useRef<number | null>(null);

  const handleDragStart = (index: number) => {
    dragItem.current = index;
  };

  const handleDragEnter = (index: number) => {
    dragOverItem.current = index;
  };

  const handleDragEnd = () => {
    if (dragItem.current !== null && dragOverItem.current !== null) {
      const updatedItems = Array.from(order);
      const [draggedItem] = updatedItems.splice(dragItem.current, 1);
      updatedItems.splice(dragOverItem.current, 0, draggedItem);

      setOrder(updatedItems);
    }
    dragItem.current = null;
    dragOverItem.current = null;
  };

  return {
    order,
    handleDragStart,
    handleDragEnter,
    handleDragEnd,
    setOrder,
  };
};
