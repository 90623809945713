import React from 'react';
import useHitterStats from "../../hooks/stat/useHitterStats";
import HitterFilterComponent from "../filter/HitterFilter";
import HitterStatTable from "../stat/HitterStatTable";
import StatsPage from './StatsPage';
import {HitterStatRes} from '../../type/res/HitterStatRes';
import {HitterFilterCriteria} from '../../type/HitterFilterCriteria';
import LoadingOrError from "../common/LoadingOrError";
import {TableContainer} from "../common/StatTable";

interface HitterStatPageProps {
  position: string;
  onPositionChange: (position: string) => void;
}

const HitterStatsPage: React.FC<HitterStatPageProps> = ({ position, onPositionChange }) => (
  <StatsPage<HitterStatRes, HitterFilterCriteria>
    useStats={useHitterStats}
    position={position}
    onPositionChange={onPositionChange}
  >
    {({
        stats,
        error,
        page,
        pageSize,
        handleFilterChange,
        filterCriteria,
        year,
        setYear,
        statSort,
      }) => (
      <>
        <HitterFilterComponent
          filterCriteria={filterCriteria}
          onFilterChange={handleFilterChange}
          year={year}
          onYearChange={setYear}
        />
        <TableContainer>
          <LoadingOrError error={error}>
            <HitterStatTable
              currentPage={page.currentPage}
              pageSize={pageSize}
              stats={stats}
              statSort={statSort}
            />
          </LoadingOrError>
        </TableContainer>
      </>
    )}
  </StatsPage>
);

export default HitterStatsPage;
