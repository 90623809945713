import {useState} from 'react';
import {PlayerDetailApi} from '../../apis/PlayerDetailApi';
import {PlayerDetailRes} from '../../type/res/PlayerDetailRes';
import {useQuery} from "react-query";

const usePlayerCompare = () => {
  const [playerId, setPlayerId] = useState<number | null>(null);

  const handlePlayerSelect = (newPlayerId: number) => {
    setPlayerId(newPlayerId);
  };

  const numberPlayerId = Number(playerId);

  const query = useQuery<PlayerDetailRes, Error>(
      ['playerDetail', numberPlayerId],
      () => PlayerDetailApi.getDetail(numberPlayerId),
      {
        enabled: numberPlayerId > 0,
        retry: false,
        staleTime: 10 * 60 * 1000,
        cacheTime: 10 * 60 * 1000,
        onError: () => {},
      }
  );

  return {
    playerId,
    setPlayerId,
    handlePlayerSelect,
    ...query,
  };
};

export default usePlayerCompare;
