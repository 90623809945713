import useQueryString from "../common/useQueryString";

const DEFAULT_YEAR = 2024;

const useYear = (initialYear: number = DEFAULT_YEAR) => {
  const [year, setYear] = useQueryString('year', initialYear.toString());
  const parsedYear = parseInt(year, 10);

  const handleYearChange = (newYear: number) => {
    setYear(newYear.toString());
  };

  return { year: parsedYear, setYear: handleYearChange };
};

export default useYear;
