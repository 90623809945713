import React from 'react';
import styled from 'styled-components';
import {PlayerDetailRes} from "../../../type/res/PlayerDetailRes";

interface PlayerInfoDisplayProps {
  playerDetail?: PlayerDetailRes;
}

const PlayerInfoDisplay = ({playerDetail}: PlayerInfoDisplayProps) => {
  return (
      <PlayerInfoContainer>
        {playerDetail ? (
            <PlayerInfoItem>
              <PlayerName>{playerDetail.name} ({playerDetail.team})</PlayerName>
              <TeamAndPositionContainer>
                <PlayerPosition>{playerDetail.position}</PlayerPosition>
                <Divider>|</Divider>
                <PlayerPosition>{playerDetail.handInfo}</PlayerPosition>
              </TeamAndPositionContainer>
            </PlayerInfoItem>
        ) : (
            <EmptyPlaceholder>선수 선택</EmptyPlaceholder>
        )}
      </PlayerInfoContainer>
  );
};

const PlayerInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
`;

const PlayerInfoItem = styled.div`
  align-items: center;
  justify-content: center;
`;

const PlayerName = styled.div`
  font-weight: bold;
  font-family: jua;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 10px;
`;

const TeamAndPositionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;


const PlayerPosition = styled.div`
  font-family: jua;
  font-size: 1rem;
  @media (max-width: 720px) {
    font-size: 14px;
  }
`;

const Divider = styled.div`
  margin: 0 10px;
  font-size: 1rem;
  color: #666;

`;

const EmptyPlaceholder = styled.div`
  font-size: 14px;
  color: #999;
`;

export default PlayerInfoDisplay;
