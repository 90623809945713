import {HitterStatRes} from '../../type/res/HitterStatRes';
import {HitterStatApi} from '../../apis/HitterStatApi';
import useHitterFilter from '../filter/useHitterFilter';
import {HitterFilterCriteria} from '../../type/HitterFilterCriteria';
import useStats from "./useStat";

const useHitterStats = () => {
  return useStats<HitterStatRes, HitterFilterCriteria>(
    HitterStatApi.getStats,
    'hitterStats',
    useHitterFilter,
    { team: '', minPlateAppearance: 200 },
    'plateAppearance'
  );
};

export default useHitterStats;
