import React from "react";
import {POSITIONS, PositionType} from "../../../constant/PositionConstants";
import SegmentedControl from "./SegmentControl";

interface CompareButtonGroupProps {
  activePosition: PositionType;
  onPositionChange: (position: PositionType) => void;
}

const CompareButtonGroup = ({ activePosition, onPositionChange }: CompareButtonGroupProps) => {
  const options = [
    { label: POSITIONS.HITTER + " 비교", value: POSITIONS.HITTER },
    { label: POSITIONS.PITCHER + " 비교", value: POSITIONS.PITCHER },
  ];

  return (
      <SegmentedControl
          activeOption={activePosition}
          options={options}
          onOptionChange={onPositionChange}
      />
  );
};

export default CompareButtonGroup;
