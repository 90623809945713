import React from 'react';
import {HitterFilterCriteria} from '../../type/HitterFilterCriteria';
import {FilterContainer} from '../common/Filter';
import YearFilter from './YearFilter';
import TeamFilter from './TeamFilter';
import {PA_OPTIONS} from '../../type/FilterConst';
import FilterBox from './FilterBox';

const yearOptions = [2021, 2022, 2023, 2024].map(year => ({ value: year, label: year.toString() }));

interface HitterFilterProps {
  filterCriteria: HitterFilterCriteria;
  onFilterChange: (newCriteria: Partial<HitterFilterCriteria>) => void;
  year: number;
  onYearChange: (year: number) => void;
}

const HitterFilter = ({filterCriteria, onFilterChange, year, onYearChange}: HitterFilterProps) => {
  const handlePAChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(event.target.value, 10);
    onFilterChange({minPlateAppearance: value});
  };


  return (
    <FilterContainer>
      <YearFilter year={year} onYearChange={onYearChange} yearOptions={yearOptions}/>
      <TeamFilter team={filterCriteria.team} onTeamChange={(team) => onFilterChange({team})}/>
      <FilterBox
        id="pa-filter"
        value={filterCriteria.minPlateAppearance}
        onChange={handlePAChange}
        options={PA_OPTIONS.map(option => ({value: option, label: option.toString()}))}
        prefix="최소 타석"
      />
    </FilterContainer>
  );
};

export default HitterFilter;
