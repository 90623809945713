import styled from "styled-components";

interface CustomButtonProps {
  $backgroundColor?: string;
}

export const CustomButton = styled.button<CustomButtonProps>`
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  background: ${({ $backgroundColor }) => $backgroundColor || '#dc3545'};
  font-size: 16px;
  cursor: pointer;
`;
