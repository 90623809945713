import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {POSITIONS, PositionType} from "../../constant/PositionConstants";

interface CompareContext {
  playerId: number | undefined;
  setPlayerId: (id: number | undefined) => void;
  position: PositionType;
  setPosition: (position: PositionType) => void;
}

const useCompareContext = (): CompareContext => {
  const [playerId, setPlayerId] = useState<number | undefined>();
  const [position, setPosition] = useState<PositionType>(POSITIONS.HITTER);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const playerIdFromQuery = queryParams.get("playerId");
    const positionFromQuery = queryParams.get("position") as PositionType;

    if (playerIdFromQuery && positionFromQuery && Object.values(POSITIONS).includes(positionFromQuery)) {
      setPlayerId(Number(playerIdFromQuery));
      setPosition(positionFromQuery);

      // 쿼리 스트링 제거
      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate]);

  return {
    playerId,
    setPlayerId,
    position,
    setPosition,
  };
};

export default useCompareContext;
