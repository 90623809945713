import React, {useEffect} from "react";
import AdminHeaderComponent from "./AdminHeaderComponent";
import ExcelUpload from "./excel/ExcelUpload";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {PATH} from "../../constant/Path";
import {getSession} from "../auth/function/Login";

const AdminApp = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const sessionKey = getSession()
    if (!sessionKey) {
      navigate(PATH.LOGIN);
    }
  }, [navigate]);

  return (
      <div className="관리자">
        <AdminHeaderComponent />
        <Outlet />
        {location.pathname === PATH.ADMIN && <ExcelUpload />}
      </div>
  );
}

export default AdminApp;
