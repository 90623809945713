import {css} from 'styled-components';

const BaseNavLink = css`
    text-decoration: none;
    color: #333;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    font-family: jua;

    &:hover {
        color: #0056b3;
    }
    
`;

export default BaseNavLink;
