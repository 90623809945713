import {client} from "../../../apis/ApiClient";

export const login = async (request: LoginRequest) : Promise<LoginResponse> => {
  const response = await client.post<LoginResponse>('/login', request);
  return response.data
}

export interface LoginRequest {
  loginId: string;
  password: string;
}

export interface LoginResponse {
  sessionKey: string;
}
