import App from "../App";
import {createBrowserRouter} from "react-router-dom";
import {PATH} from "../constant/Path";
import React from "react";
import PlayerDetail from "../components/detail/PlayerDetail";
import AdminApp from "../admin/components/AdminApp";
import GlossaryAdminComponent from "../admin/components/glossary/GlossaryAdminComponent";
import LoginPage from "../admin/auth/components/LoginPage";
import NotFoundPage from "./error/NotFoundPage";
import CacheRenewComponent from "../admin/components/cache/CacheRenewPage";
import AnnouncementAdminComponent from "../admin/components/announcement/AnnouncementAdminComponent";
import Glossary from "../components/common/faq/Glossary";
import PlayerComparePage from "../page/PlayerComparePage";
import PostListPage from "../admin/components/post/PostListPage";
import PostDetail from "../admin/components/post/PostDetail";
import EditPost from "../admin/components/post/PostEdit";
import CreatePost from "../admin/components/post/PostCreate";

const router = createBrowserRouter([
  {
    path: PATH.HOME,
    element: <App/>,
    children: [
      {
        path: PATH.PLAYER_DETAIL,
        element: <PlayerDetail/>
      },
      {
        path: PATH.COMPARE_PLAYER,
        element: <PlayerComparePage/>
      },
      {
        path: PATH.FAQ,
        element: <Glossary/>
      },
    ],
  },
  {
    path: PATH.ADMIN,
    element: <AdminApp/>,
    children: [
      {
        path: PATH.GLOSSARY,
        element: <GlossaryAdminComponent/>
      },
      {
        path: PATH.ANNOUNCEMENT,
        element: <AnnouncementAdminComponent/>
      },
      {
        path: PATH.CACHE,
        element: <CacheRenewComponent/>
      },
      {
        path: PATH.POST,
        element: <PostListPage/>
      },
      {
        path: PATH.POST_DETAIL,
        element: <PostDetail/>,
      },
      {
        path: PATH.POST_EDIT,
        element: <EditPost/>,
      },
      {
        path: PATH.POST_NEW,
        element: <CreatePost/>,
      }
    ]
  },
  {
    path: PATH.LOGIN,
    element: <LoginPage/>
  },
  {
    path: "*",
    element: <NotFoundPage/>
  }
]);

export default router;
