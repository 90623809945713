import React from 'react';
import {PitcherStatRes} from '../../type/pitcher/PitcherStatRes';
import {StatSort} from '../../hooks/sort/useSort';
import StatTable from './StatTable';
import PitcherSort from '../sort/PitcherSort';
import {PitcherStatRender} from "./PitcherStatRender";

const PITCHER_STAT_COLUMNS: { header: string; field: keyof PitcherStatRes | 'index' }[] = [
  {header: '번호', field: 'index'},
  {header: '이름', field: 'name'},
  {header: '나이', field: 'age'},
  {header: '연도', field: 'year'},
  {header: '팀', field: 'team'},
  {header: '이닝', field: 'innings'},
  {header: 'ERA', field: 'era'},
  {header: 'FIP', field: 'fip'},
  {header: 'RA9', field: 'ra9'},
  {header: '탈삼진', field: 'strikeOut'},
  {header: '볼넷', field: 'baseOnBalls'},
  {header: 'xERA', field: 'yfxEra'},
  {header: 'SIERA', field: 'yfSiera'},
  {header: 'Whiff %', field: 'whiff'},
  {header: 'Chase %', field: 'chasePercent'},
  {header: 'EV', field: 'exitVelocity'},
  {header: 'HardHit %', field: 'hardHitPercent'},
  {header: 'Barrel %', field: 'barrelPercent'},
  {header: 'Barrel/PA', field: 'barrelByPaPercent'},
  {header: 'IPA %', field: 'ipaPercent'},
  {header: 'ICR %', field: 'icrPercent'},
  {header: 'xwOBAcon', field: 'expectedWobaCon'},
  {header: 'xwOBA', field: 'expectedWoba'},
  {header: 'CSW %', field: 'callStrikePercent'}
];

interface PitcherStatComponentProps {
  stats: PitcherStatRes[];
  currentPage: number;
  pageSize: number;
  statSort: StatSort<PitcherStatRes>;
}

const PitcherStatTable = ({stats, currentPage, pageSize, statSort}: PitcherStatComponentProps) => (
  <StatTable<PitcherStatRes>
    columns={PITCHER_STAT_COLUMNS}
    stats={stats}
    currentPage={currentPage}
    pageSize={pageSize}
    statSort={statSort}
    SortComponent={PitcherSort}
    renderCell={(stat, column) => <PitcherStatRender stat={stat} column={column} />}
  />
);

export default PitcherStatTable;
