import React from 'react';
import FilterBox from "./FilterBox";

interface YearFilterProps {
  year: number;
  onYearChange: (year: number) => void;
  yearOptions: { value: number, label: string }[];
}

const YearFilter = ({ year, onYearChange, yearOptions }: YearFilterProps) => {
  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onYearChange(parseInt(event.target.value, 10));
  };

  return (
    <FilterBox
      id="year-filter"
      value={year}
      onChange={handleYearChange}
      options={yearOptions}
      prefix={'시즌'}
    />
  );
};

export default YearFilter;
