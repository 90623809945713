import React from 'react';
import styled from 'styled-components';

interface RankBarProps {
  rank: number | null | undefined;
}

const RankBar= ({ rank } : RankBarProps) => {
  const getColor = (rank: number | null | undefined) => {
    if (rank === undefined || rank === null) return '#d3d3d3';

    let r = 0;
    let g = 0;
    let b = 0;

    if (rank <= 50) {
      const ratio = rank / 50;
      r = Math.round(211 * ratio);
      g = Math.round(211 * ratio);
      b = 255 - Math.round(44 * ratio);
    } else {
      const ratio = (rank - 50) / 50;
      r = 211 + Math.round(44 * ratio);
      g = 211 - Math.round(211 * ratio);
      b = 211 - Math.round(211 * ratio);
    }

    return `rgb(${r}, ${g}, ${b})`;
  };

  if (rank === null) {
    return <div>샘플이 부족합니다.</div>;
  }

  const color = getColor(rank);

  return (
    <BarContainer>
      <Bar rank={rank} style={{ backgroundColor: color, width: `${rank || 0}%` }}>
        <RankLabel style={{ backgroundColor: color }}>{rank !== undefined ? rank : 'N/A'}</RankLabel>
      </Bar>
    </BarContainer>
  );
};

const BarContainer = styled.div`
  height: 15px;
  background-color: lightgrey;
  border-radius: 5px;
  margin: 5px 0;
  overflow: visible;
  flex-grow: 1;
`;

const Bar = styled.div<{ rank?: number | null }>`
  height: 100%;
  width: 100%;
  color: white;
  text-align: center;
  border-radius: 5px;
  position: relative;
`;

const RankLabel = styled.span`
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    font-weight: bold;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    border-radius: 50%;
    border: 2px solid white;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;

    @media (max-width: 1200px) {
        width: 25px;
        height: 25px;
        line-height: 25px;
        font-size: 16px;
    }

    @media (max-width: 992px) {
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 15px;
    }

    @media (max-width: 768px) {
        width: 18px;
        height: 18px;
        line-height: 18px;
        font-size: 10px;
    }
`;

export default RankBar;
