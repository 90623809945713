import React from 'react';
import {HitterStatRes} from '../../type/res/HitterStatRes';
import {StatSort} from '../../hooks/sort/useSort';
import StatTable from './StatTable';
import HitterSort from '../sort/HitterSort';
import {HitterStatRender} from "./HitterStatRender";

const HITTER_STAT_COLUMNS: { header: string; field: keyof HitterStatRes | 'index' }[] = [
  { header: '번호', field: 'index' },
  { header: '이름', field: 'name' },
  { header: 'playerId', field: 'playerId' },
  { header: '나이', field: 'age' },
  { header: '연도', field: 'year' },
  { header: '팀', field: 'team' },
  { header: '타석', field: 'plateAppearance' },
  { header: 'AVG', field: 'avg' },
  { header: 'OBP', field: 'obp' },
  { header: 'SLG', field: 'slg' },
  { header: 'OPS', field: 'ops' },
  { header: 'wOBA', field: 'woba' },
  { header: 'EV', field: 'exitVelocity' },
  { header: 'sd(LA)', field: 'solidLaunchAngle' },
  { header: 'SwSp %', field: 'sweetSpotPercent' },
  { header: 'Chase %', field: 'chasePercent' },
  { header: 'Whiff %', field: 'whiff' },
  { header: 'CSW %', field: 'callStrikePercent' },
  { header: 'Zone Judgement', field: 'swingDecision' },
  { header: 'HardHit %', field: 'hardHitPercent' },
  { header: 'Barrel %', field: 'barrelPercent' },
  { header: 'Barrel/PA', field: 'barrelByPaPercent' },
  { header: 'ICR %', field: 'icrPercent' },
  { header: 'IPA %', field: 'ipaPercent' },
  { header: 'xBA', field: 'expectedBattingAverage' },
  { header: 'xwOBAcon', field: 'expectedWobaCon' },
  { header: 'xwOBA', field: 'expectedWoba' }
];

interface HitterStatComponentProps {
  stats: HitterStatRes[];
  currentPage: number;
  pageSize: number;
  statSort: StatSort<HitterStatRes>;
}

const HitterStatTable = ({ stats, currentPage, pageSize, statSort }: HitterStatComponentProps) => (
  <StatTable<HitterStatRes>
    columns={HITTER_STAT_COLUMNS}
    stats={stats}
    currentPage={currentPage}
    pageSize={pageSize}
    statSort={statSort}
    SortComponent={HitterSort}
    renderCell={(stat, column) => <HitterStatRender stat={stat} column={column} />}
  />
);

export default HitterStatTable;
