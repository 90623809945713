import React from 'react';
import styled from 'styled-components';
import {HitterDetailRes, PlayerDetailRes} from '../../../type/res/PlayerDetailRes';
import useRankBarYear from "../../../hooks/detail/useRankBarYear";
import HitterCompareStat from "./HitterCompareStat";
import PlayerDetailHeaderPair from "../info/PlayerDetailHeaderPair";

interface HitterCompareStatBarProps {
  leftPlayerDetail?: PlayerDetailRes;
  rightPlayerDetail?: PlayerDetailRes;
}

const HitterCompareStatBar = ({ leftPlayerDetail, rightPlayerDetail }: HitterCompareStatBarProps) => {
  const leftHitters = leftPlayerDetail?.hitterStats || [];
  const rightHitters = rightPlayerDetail?.hitterStats || [];
  const leftPosition = leftPlayerDetail?.position || "";
  const rightPosition = rightPlayerDetail?.position || "";

  const {
    selectedYear: leftSelectedYear,
    handleYearChange: handleLeftYearChange,
    years: leftYears,
  } = useRankBarYear(leftPosition, leftHitters, []);

  const {
    selectedYear: rightSelectedYear,
    handleYearChange: handleRightYearChange,
    years: rightYears,
  } = useRankBarYear(rightPosition, rightHitters, []);

  const leftFilteredData = leftHitters.filter((player: HitterDetailRes) => player.year === leftSelectedYear);
  const rightFilteredData = rightHitters.filter((player: HitterDetailRes) => player.year === rightSelectedYear);

  const maxLength = Math.max(leftFilteredData.length, rightFilteredData.length);

  return (
      <PlayerDetailSavantBarContainer>
        <PairContainer>
          <PlayerDetailHeaderPair
              playerDetail={leftPlayerDetail}
              selectedYear={leftSelectedYear}
              handleYearChange={handleLeftYearChange}
              years={leftYears}
          />
          <PlayerDetailHeaderPair
              playerDetail={rightPlayerDetail}
              selectedYear={rightSelectedYear}
              handleYearChange={handleRightYearChange}
              years={rightYears}
          />
        </PairContainer>
        <StatsContainer>
          {Array.from({ length: maxLength }).map((_, index) => {
            const leftPlayer = leftFilteredData[index] || null;
            const rightPlayer = rightFilteredData[index] || null;

            return (
                <HitterCompareStat
                    key={index}
                    leftPlayer={leftPlayer}
                    rightPlayer={rightPlayer}
                />
            );
          })}
        </StatsContainer>
      </PlayerDetailSavantBarContainer>
  );
};

export const PlayerDetailSavantBarContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;
`;

export const PairContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
`;


export const StatsContainer = styled.div`
  max-width: 1200px;
  flex-direction: column;
  align-items: center;
`;

export default HitterCompareStatBar;
