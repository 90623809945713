import React from 'react';
import {FilterContainer} from '../common/Filter';
import YearFilter from './YearFilter';
import TeamFilter from './TeamFilter';
import {INNINGS_OPTIONS} from '../../type/FilterConst';
import {PitcherFilterCriteria} from "../../type/pitcher/PitcherFilterCriteria";
import FilterBox from "./FilterBox";


const yearOptions = [2022, 2023, 2024].map(year => ({ value: year, label: year.toString() }));

interface PitcherFilterProps {
  filterCriteria: PitcherFilterCriteria;
  onFilterChange: (newCriteria: Partial<PitcherFilterCriteria>) => void;
  year: number;
  onYearChange: (year: number) => void;
}

  const PitcherFilter = ({filterCriteria, onFilterChange, year, onYearChange}: PitcherFilterProps) => {
    const handleInningChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const value = parseInt(event.target.value, 10);
      onFilterChange({innings: value});
    };

    return (
      <FilterContainer>
        <YearFilter year={year} onYearChange={onYearChange} yearOptions={yearOptions}/>
        <TeamFilter team={filterCriteria.team} onTeamChange={(team) => onFilterChange({team})}/>
        <FilterBox
          id="innning-filter"
          value={filterCriteria.innings}
          onChange={handleInningChange}
          options={INNINGS_OPTIONS.map(option => ({ value: option, label: option.toString() }))}
          prefix="최소 이닝"
        />
      </FilterContainer>
    );
  };

export default PitcherFilter;
