import React from 'react';
import {PlayerDetailRes} from "../../../type/res/PlayerDetailRes";
import {CompareStatBarContainer, HitterDetailSavantBarContainer} from "./HitterCompareTotalStatBar";
import PitcherCompareStatBar from "./PitcherCompareStatBar";

interface PitcherCompareTotalStatBarProps {
  leftPlayerDetail?: PlayerDetailRes;
  rightPlayerDetail?: PlayerDetailRes;
}

const PitcherCompareTotalStatBar = ({leftPlayerDetail, rightPlayerDetail}: PitcherCompareTotalStatBarProps) => {
  return (
      <CompareStatBarContainer>
        <HitterDetailSavantBarContainer>
          {(leftPlayerDetail || rightPlayerDetail) ? (
              <PitcherCompareStatBar leftPlayerDetail={leftPlayerDetail} rightPlayerDetail={rightPlayerDetail}/>
          ) : (
              <div/>
          )}
        </HitterDetailSavantBarContainer>
      </CompareStatBarContainer>
  );
};

export default PitcherCompareTotalStatBar;
