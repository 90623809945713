import React from "react";
import styled from "styled-components";

interface ExpandButtonProps {
  expanded: boolean;
  onClick: () => void;
  showButton: boolean;
}

const ExpandButton = ({ expanded, onClick, showButton }: ExpandButtonProps) => {
  if (!showButton) return null;

  return (
      <StyledButton $expanded={expanded} onClick={onClick}>
      </StyledButton>
  );
};

export default ExpandButton;

const StyledButton = styled.button<{ $expanded: boolean }>`
  background-color: #f1f1f1;
  color: #231f21;
  border: none;
  padding: 2px 6px;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 1.25rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:before {
    content: ${({$expanded}) => ($expanded ? '"✖"' : '"▾"')};
    font-size: 1.25rem;
  }

  &:hover {
    background-color: #21231f;
    color: #ffffff;
  }
`;
