import React from 'react';
import styled from 'styled-components';
import {HitterDetailRes, PitcherDetailRes} from '../../../type/res/PlayerDetailRes';
import HitterStatsBar from './HitterStatsBar';
import PitcherStatsBar from './PitcherStatsBar';
import DetailHeaderComponent from "../DetailHeaderComponent";
import useRankBarYear from "../../../hooks/detail/useRankBarYear";
import {isPitcher} from "../../../functions/common";

interface PlayerDetailSavantBarProps {
  hitters: HitterDetailRes[];
  pitchers: PitcherDetailRes[];
  position: string;
  onCompareClick?: () => void;
}

const PlayerDetailSavantBar = ({hitters, pitchers, position, onCompareClick}: PlayerDetailSavantBarProps) => {
  const {selectedYear, handleYearChange, years} = useRankBarYear(position, hitters, pitchers);
  const data = isPitcher(position) ? pitchers : hitters;
  const filteredData = isPitcher(position)
      ? (data as PitcherDetailRes[]).filter((player: PitcherDetailRes) => player.year === selectedYear)
      : (data as HitterDetailRes[]).filter((player: HitterDetailRes) => player.year === selectedYear);

  return (
      <PlayerDetailSavantBarContainer>
        <DetailHeaderComponent
            selectedYear={selectedYear}
            handleYearChange={handleYearChange}
            years={years}
        >
          <DetailHeaderComponent.Button>
            <CompareButton onClick={onCompareClick}>
              기록 비교
            </CompareButton>
          </DetailHeaderComponent.Button>
        </DetailHeaderComponent>
        {filteredData.map((player: HitterDetailRes | PitcherDetailRes, index: number) => (
            isPitcher(position) ?
                <PitcherStatsBar key={index} player={player as PitcherDetailRes}/> :
                <HitterStatsBar key={index} player={player as HitterDetailRes}/>
        ))}
      </PlayerDetailSavantBarContainer>
  );
};

const PlayerDetailSavantBarContainer = styled.div`
  width: 50%;
  margin: 0 auto;
  text-align: center;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const CompareButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  font-family: jua;
  color: white;
  background-color: #231f21;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

export default PlayerDetailSavantBar;
