import React, {useEffect} from "react";
import styled from "styled-components";
import {PositionType} from "../constant/PositionConstants";
import CompareSearch from "../components/compare/search/CompareSearch";
import usePlayerCompare from "../hooks/compare/usePlayerCompareDetail";
import HitterCompareTotalStatBar from "../components/compare/bar/HitterCompareTotalStatBar";

interface HitterComparePageProps {
  playerId?: number
  position: PositionType
}

const HitterComparePage = ({playerId, position} : HitterComparePageProps) => {

  const {
    handlePlayerSelect: handleLeftPlayerSelect,
    setPlayerId,
    data: leftPlayerDetail,
    isLoading: isLoadingLeft,
  } = usePlayerCompare();


  const {
    handlePlayerSelect: handleRightPlayerSelect,
    data: rightPlayerDetail,
    isLoading: isLoadingRight,
  } = usePlayerCompare();

  useEffect(() => {
    if (playerId) {
      setPlayerId(playerId);
    }
  }, [playerId, setPlayerId]);

  return (
      <Container>
        <SearchContainer>
          <LeftSearchContainer>
            <CompareSearch position={position} onSelectPlayer={handleLeftPlayerSelect}/>
          </LeftSearchContainer>
          <RightSearchContainer>
            <CompareSearch position={position} onSelectPlayer={handleRightPlayerSelect}/>
          </RightSearchContainer>
        </SearchContainer>

        {(!isLoadingLeft || !isLoadingRight) && (leftPlayerDetail || rightPlayerDetail) ? (
            <HitterCompareTotalStatBar
                leftPlayerDetail={leftPlayerDetail}
                rightPlayerDetail={rightPlayerDetail}
            />
        ) : (
            <p></p>
        )}
      </Container>
  );
};

export default HitterComparePage;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

export const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between; 
  margin-bottom: 20px;
`;

export const LeftSearchContainer = styled.div`
  flex: 1;
  margin-right: 1rem; /* 좌측 여백 */
`;

export const RightSearchContainer = styled.div`
  flex: 1;
  margin-left: 1rem; /* 우측 여백 */
`;
