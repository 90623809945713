import React from 'react';
import usePitcherStats from "../../hooks/stat/usePitcherStats";
import PitcherFilter from "../filter/PitcherFilter";
import PitcherStatTable from "../stat/PitcherStatTable";
import StatsPage from './StatsPage';
import {PitcherStatRes} from '../../type/pitcher/PitcherStatRes';
import {PitcherFilterCriteria} from '../../type/pitcher/PitcherFilterCriteria';
import {TableContainer} from "../common/StatTable";
import LoadingOrError from "../common/LoadingOrError";

interface PitcherStatPageProps {
  position: string;
  onPositionChange: (position: string) => void;
}

const PitcherStatPage: React.FC<PitcherStatPageProps> = ({ position, onPositionChange }) => (
  <StatsPage<PitcherStatRes, PitcherFilterCriteria>
    useStats={usePitcherStats}
    position={position}
    onPositionChange={onPositionChange}
  >
    {({
        stats,
        error,
        page,
        pageSize,
        handleFilterChange,
        filterCriteria,
        year,
        setYear,
        statSort,
      }) => (
      <>
        <PitcherFilter
          filterCriteria={filterCriteria}
          onFilterChange={handleFilterChange}
          year={year}
          onYearChange={setYear}
        />
        <TableContainer>
          <LoadingOrError error={error}>
            <PitcherStatTable
              currentPage={page.currentPage}
              pageSize={pageSize}
              stats={stats}
              statSort={statSort}
            />
          </LoadingOrError>
        </TableContainer>
      </>
    )}
  </StatsPage>
);

export default PitcherStatPage;
