import {useMutation} from "react-query";
import {login, LoginRequest, LoginResponse} from "../apis/LoginApi";
import {AxiosError} from "axios";
import {ErrorResponse} from "../../../common/Error";
import {setSession} from "../function/Login";

export const useLogin = () => {
  const mutation = useMutation(
      (request: LoginRequest) => login(request),
      {
        onSuccess: (response: LoginResponse) => {
          setSession(response.sessionKey)
        },
        onError: (error: AxiosError<ErrorResponse>) => {
        },
      }
  )

  return {
    login: mutation.mutate,
    loading: mutation.isLoading,
    error: mutation.isError ? mutation.error : null,
  };
};
