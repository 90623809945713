import {client} from "./ApiClient";
import {HitterStatRes} from "../type/res/HitterStatRes";


export class HitterStatApi {
  static async getStats(year: number): Promise<HitterStatRes[]> {
    let response = await client.get<HitterStatRes[]>(`/hitters`, {
      params: {
        year: year
      }
    });
    return response.data
  }
}
