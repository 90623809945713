import {client} from "./ApiClient";
import {PlayerSearchRes} from "../type/res/PlayerSearchRes";

export class PlayerSearchApi {
  static async getPlayers(keyword: string): Promise<PlayerSearchRes[]> {
    let response = await client.get<PlayerSearchRes[]>('/player-search', {
      params: { keyword }
    });
    return response.data;
  }
}