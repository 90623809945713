import axios, {AxiosRequestConfig} from "axios";
import {getSession} from "../auth/function/Login";

const BASE_URL  = process.env.REACT_APP_SEVER_URL

const defaultConfig: AxiosRequestConfig = {
  baseURL: BASE_URL
};

export const client = axios.create(defaultConfig);

client.interceptors.request.use(
    (config) => {
      const sessionKey = getSession()
      if (sessionKey) {
        config.headers.Authorization = `Bearer ${sessionKey}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);
