import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useFetchPostDetail} from "../../hooks/query/usePost";
import {PATH} from "../../../constant/Path";
import ConfirmButton from "../../styles/ConfirmButton";
import PostHomeButton from "./PostHomeButton";
import PostContent from "./PostContent";
import styled from "styled-components";

const PostDetail = () => {
  const {postId} = useParams<{ postId: string }>();
  const navigate = useNavigate();
  const {postDetail, error, isLoading} = useFetchPostDetail(Number(postId));

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading post: {error.message}</div>;
  }

  if (!postDetail) {
    return <div>Post not found</div>;
  }

  const handleEdit = () => {
    if (postId) {
      navigate(PATH.POST_EDIT.replace(":postId", postId));
    }
  };

  return (
      <div>
        <StyledTitle>{postDetail.title}</StyledTitle>
        <PostContent content={postDetail.content}/>
        <ConfirmButton title={"수정"} backgroundColor={"#007bff"} onClick={handleEdit}/>
        <PostHomeButton title={"목록"} backgroundColor={"#3f3f41"}/>
      </div>
  );
};

export default PostDetail;

const StyledTitle = styled.h1`
  font-size: 2.5rem; /* 타이틀 크기 */
  font-family: jua;
  color: #333; /* 기본 글자 색상 */
  text-align: center; /* 가운데 정렬 */
  margin-bottom: 1.5rem; /* 하단 여백 */
  position: relative; /* 가상 요소를 위한 상대 위치 */
  padding: 0.5rem 0; /* 상하 패딩 */
  line-height: 1.2; /* 줄 간격 */
`;
