import React from "react";
import styled from "styled-components";
import EditableGlossaryItem from "./EditableGlossaryItem";
import {FormField} from "../../styles/FormField";
import {useGlossaryAdmin} from "../../hooks/query/useGlossary";
import {useGlossaryOrderEdit} from "../../hooks/useGlossaryOrderEdit";
import AdminButton from "../common/AdminButton";

const GlossaryAdminComponent: React.FC = () => {
  const {
    glossaries,
    error,
    isLoading,
    newGlossary,
    handleInputChange,
    handleAddGlossary,
    updateGlossary,
    deleteGlossary,
  } = useGlossaryAdmin();

  const {
    glossaryOrder,
    isEditMode,
    toggleEditMode,
    handleDragStart,
    handleDragEnter,
    handleDragEnd,
    handleUpdateOrder,
  } = useGlossaryOrderEdit(glossaries);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>로딩 실패</div>;

  const successButtonColor = "#5b94de";

  return (
      <Container>
        <h1>도움말 관리</h1>
        <Form>
          <FormField label="제목" name="name" value={newGlossary.name} placeholder="제목을 적어주세요" onChange={handleInputChange}/>
          <FormField label="설명" name="description" value={newGlossary.description} placeholder="설명을 적어주세요" onChange={handleInputChange}
              textarea
          />
          <AdminButton label={"도움말 등록"} onClick={handleAddGlossary} backgroundColor={successButtonColor}/>
        </Form>
        <GlossaryList>~
          {glossaryOrder.map((glossary, index) => (
              <div
                  key={glossary.id}
                  draggable={isEditMode}
                  onDragStart={() => handleDragStart(index)}
                  onDragEnter={() => handleDragEnter(index)}
                  onDragEnd={handleDragEnd}
                  style={{
                    marginBottom: "10px",
                    cursor: isEditMode ? "grab" : "default",
                  }}
              >
                <EditableGlossaryItem
                    glossary={glossary}
                    updateGlossary={updateGlossary}
                    deleteGlossary={deleteGlossary}
                />
              </div>
          ))}
        </GlossaryList>
        <ButtonGroup>
          <AdminButton onClick={toggleEditMode} label={isEditMode ? "편집 취소" : "순서 편집"} backgroundColor={isEditMode ? "#d9534f" : successButtonColor}/>
          {isEditMode && (
              <AdminButton onClick={handleUpdateOrder} label="순서 저장" backgroundColor="#5bc0de"/>
          )}
        </ButtonGroup>
      </Container>
  );
};

export default GlossaryAdminComponent;

const Container = styled.div`
  padding: 20px;
  background-color: #f9f9f9;

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const GlossaryList = styled.div`
  margin-top: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;
