import React from 'react';
import styled from 'styled-components';
import {PlayerDetailRes} from "../../../type/res/PlayerDetailRes";
import PlayerInfoDisplay from "./PlayerInfoDisplay";
import DetailHeaderComponent from "../../detail/DetailHeaderComponent";

interface PlayerDetailHeaderPairProps {
  playerDetail?: PlayerDetailRes;
  selectedYear: number | string;
  handleYearChange: (value: number | string) => void;
  years: { value: number | string; label: number | string }[];
}

const PlayerDetailHeaderPair = ({ playerDetail, selectedYear, handleYearChange, years }: PlayerDetailHeaderPairProps) => {
  return (
      <PairContainer>
        <PlayerInfoDisplay playerDetail={playerDetail} />
        <DetailHeaderComponent
            selectedYear={selectedYear}
            handleYearChange={handleYearChange}
            years={years}
        />
      </PairContainer>
  );
};

const PairContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

export default PlayerDetailHeaderPair;
