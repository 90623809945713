import React, {useState} from 'react';
import styled from 'styled-components';
import MainSearchBar from './MainSearchBar';
import useSearch from "../../../hooks/search/useSearch";
import PlayerSearchList from "./PlayerSearchList";

const SearchContainer = styled.div`
    display: flex;
    position: relative;
    border-top: 1px solid #2a1313;
    justify-content: left;
    padding: 5px 5px;

    @media (max-width: 768px) {
        padding: 5px 5px;
    }
`;

const SearchComponent = () => {
  const { keyword, setKeyword, players, loading, error } = useSearch();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <SearchContainer>
      <MainSearchBar
        keyword={keyword}
        setKeyword={setKeyword}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setTimeout(() => setIsFocused(false), 200)}
      />
      {loading && <div>Loading...</div>}
      {error && <div>Error: {error.message}</div>}
      <PlayerSearchList isFocused={isFocused} players={players} />
    </SearchContainer>
  );
};

export default SearchComponent;

