import {client} from "./ApiClient";

export const uploadPost = async (request: PostCreateRequest): Promise<void> => {
  await client.post('/admin/post', request);
};

export const updatePost = async (postId: number, request: PostUpdateRequest): Promise<void> => {
  await client.put(`/admin/post/${postId}`, request);
};

export const deletePost = async (postId: number): Promise<void> => {
  await client.delete(`/admin/post/${postId}`);
};

export const getPosts = async (): Promise<PostResponse[]> => {
  const response = await client.get<PostResponse[]>('/admin/post');
  return response.data
};

export const getPostDetail = async (postId: number): Promise<PostDetailResponse> => {
  const response = await client.get<PostDetailResponse>(`/admin/post/${postId}`);
  return response.data
};

export interface PostCreateRequest {
  title: string,
  content: string
}

export interface PostUpdateRequest {
  title: string,
  content: string
}


export interface PostResponse {
  id: number
  title: string,
  createdDate: Date
}

export interface PostDetailResponse {
  id: number
  title: string,
  content: string
}
