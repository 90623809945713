import React from "react";
import styled from "styled-components";
import {AxiosError} from "axios";
import {ErrorResponse} from "../../common/Error";

interface ErrorMessageProps {
  error: AxiosError<ErrorResponse> | null;
  defaultMessage?: string;
}

const ErrorMessage = ({ error, defaultMessage = "알 수 없는 에러" }: ErrorMessageProps) => {
  const errorMessage = error?.response?.data?.msg || defaultMessage;

  if (!error) {
    return null;
  }

  return <StyledErrorMessage>{errorMessage}</StyledErrorMessage>;
};

export default ErrorMessage;

const StyledErrorMessage = styled.div`
  margin-top: 20px;
  color: red;
  text-align: center;
`;
