import React, {useMemo, useRef} from "react";
import ReactQuill, {Quill} from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {imageHandler} from "../../functions/imageHandler";
import ImageResize from 'quill-image-resize';
import styled from "styled-components";

Quill.register('modules/imageResize', ImageResize);

interface WriteEditorProps {
  content: string;
  onChange: (content: string) => void;
}

const WriteEditor = ({ content, onChange }: WriteEditorProps) => {
  const quillRef = useRef<ReactQuill | null>(null);

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'align',
    'color',
    'background',
    'float',
    'height',
    'width',
    'size'
  ];

  const modules = useMemo(
      () => ({
        toolbar: {
          container: [
            ["image"],
            [{ header: [1, 2, 3, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ 'align': [] }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
          handlers: {
            image: () => {
              if (quillRef.current) {
                imageHandler(quillRef as React.MutableRefObject<ReactQuill>);
              }
            },
          },
        },
        imageResize: {
          modules: ['Resize']
        }
      }),
      []
  );

  const handleChange = (content: string) => {
    onChange(content);
  };

  return (
      <EditorContainer>
        <ReactQuill
            formats={formats}
            ref={quillRef}
            theme="snow"
            modules={modules}
            value={content}
            onChange={handleChange}
        />
      </EditorContainer>
  );
};

export default WriteEditor;

const EditorContainer = styled.div`
  .ql-align-center {
    text-align: center;
  }

  .ql-align-center img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;
