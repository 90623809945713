import {client} from "./ApiClient";


export const uploadHitterExcelFile = async (formData: FormData): Promise<void> => {
  await client.post('/admin/file/hitter', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export const uploadPitcherExcelFile = async (formData: FormData): Promise<void> => {
  await client.post('/admin/file/pitcher', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}
