import {useEffect, useState} from "react";
import {GlossaryOrderUpdateRequest, GlossaryResponse, updateGlossaryOrder,} from "../apis/GlossaryAdminApi";
import {useDragAndDrop} from "./useDragAndDrop";

export const useGlossaryOrderEdit = (
    glossaries: GlossaryResponse[] | undefined
) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const { order: glossaryOrder, handleDragStart, handleDragEnter, handleDragEnd, setOrder } =
      useDragAndDrop<GlossaryResponse>(glossaries || []);

  useEffect(() => {
    if (glossaries) {
      setOrder(glossaries);
    }
  }, [glossaries, setOrder]);

  const handleUpdateOrder = async () => {
    const orderRequest: GlossaryOrderUpdateRequest = {
      glossaryIds: glossaryOrder.map((item) => item.id),
    };
    await updateGlossaryOrder(orderRequest);
    alert("순서 변경 성공!");
  };

  const toggleEditMode = () => {
    setIsEditMode((prevMode) => !prevMode);
  };

  return {
    glossaryOrder,
    isEditMode,
    toggleEditMode,
    handleDragStart,
    handleDragEnter,
    handleDragEnd,
    handleUpdateOrder,
  };
};
