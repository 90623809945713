import {useCallback} from 'react';
import useFilter from './useFilter';
import {PitcherStatRes} from '../../type/pitcher/PitcherStatRes';
import {PitcherFilterCriteria} from '../../type/pitcher/PitcherFilterCriteria';
import useQueryString from '../common/useQueryString';

const usePitcherFilter = (stats: PitcherStatRes[], initialCriteria: PitcherFilterCriteria) => {
  const [team, setTeam] = useQueryString('team', initialCriteria.team);
  const [innings, setInnings] = useQueryString('innings', initialCriteria.innings.toString());

  const filterPitchers = useCallback((stat: PitcherStatRes, criteria: PitcherFilterCriteria) => {
    return (
      (!criteria.team || stat.team === criteria.team) &&
      (criteria.innings === undefined || stat.innings >= criteria.innings)
    );
  }, []);

  const {
    filteredStats,
    filterCriteria,
    handleFilterChange
  } = useFilter<PitcherStatRes, PitcherFilterCriteria>(stats, {
    team,
    innings: parseInt(innings, 10),
  }, filterPitchers);

  const updateFilter = (newCriteria: Partial<PitcherFilterCriteria>) => {
    handleFilterChange(newCriteria);
    if (newCriteria.team !== undefined) setTeam(newCriteria.team);
    if (newCriteria.innings !== undefined) setInnings(newCriteria.innings.toString());
  };

  return {
    filteredStats,
    filterCriteria,
    handleFilterChange: updateFilter
  };
};

export default usePitcherFilter;
