import React from "react";
import styled from "styled-components";

interface TextDisplayProps {
  text: string;
}

const TextDisplay = ({ text }: TextDisplayProps) => {
  return <StyledParagraph>{text}</StyledParagraph>;
};

export default TextDisplay;

const StyledParagraph = styled.p`
  white-space: pre-wrap;
  font-size: 16px;
  color: #333;
  margin: 0 0 10px 0;
`;
