import {useMutation, useQuery, useQueryClient} from "react-query";
import {
  deletePost,
  getPostDetail,
  getPosts,
  PostCreateRequest,
  PostDetailResponse,
  PostResponse,
  PostUpdateRequest,
  updatePost,
  uploadPost
} from "../../apis/PostApi";

export const useFetchPosts = () => {
  const { data, error, isLoading, ...rest } = useQuery<PostResponse[], Error>({
    queryKey: ['posts'],
    queryFn: getPosts,
    staleTime: 10000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return { posts: data, error, isLoading, ...rest };
};

export const useFetchPostDetail = (postId: number) => {
  const { data, error, isLoading, ...rest } = useQuery<PostDetailResponse, Error>({
    queryKey: ['postDetail', postId],
    queryFn: () => getPostDetail(postId),
    staleTime: 10000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return { postDetail: data, error, isLoading, ...rest };
};

export const useAddPost = () => {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation({
    mutationFn: (newPost: PostCreateRequest) => uploadPost(newPost),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['posts'] });
      alert('게시물 생성 성공!');
    },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.msg || '게시물 생성에 실패했습니다.';
      alert(errorMessage);
    },
  });

  return { addPost: mutate, ...rest };
};

export const useUpdatePost = () => {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation({
    mutationFn: ({ postId, post }: { postId: number, post: PostUpdateRequest }) =>
        updatePost(postId, post),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['posts'] });
      queryClient.invalidateQueries({ queryKey: ['postDetail', variables.postId] });
      alert('게시물 수정 성공!');
    },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.msg || '게시물 수정에 실패했습니다.';
      alert(errorMessage);
    },
  });

  return { updatePost: mutate, ...rest };
};

export const useDeletePost = () => {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation({
    mutationFn: (postId: number) => deletePost(postId),
    onSuccess: (data, postId) => {
      queryClient.invalidateQueries({ queryKey: ['posts'] });
      queryClient.invalidateQueries({ queryKey: ['postDetail', postId] });
      alert('게시물 삭제 성공!');
    },
    onError: (error: any) => {
      alert('게시물 삭제에 실패했습니다.');
    },
  });

  return { deletePost: mutate, ...rest };
};

