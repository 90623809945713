import {getPresignedUrl} from "../apis/imageApi";
import React from "react";
import ReactQuill from "react-quill";

const handleImageUpload = async (uploadUrl: string, file: File) => {
  const res = await fetch(uploadUrl, {
    method: "put",
    body: file,
    headers: {
      "Content-Type": file.type,
    },
  });
  return res?.url;
};

export const imageHandler = async (quillRef : React.MutableRefObject<ReactQuill>) => {
  const input = document.createElement("input");
  input.type = "file";
  input.accept = "image/*";
  input.click();

  input.onchange = async () => {
    const files = input.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file) {
        try {
          const presignedRes = await getPresignedUrl(file.name);
          await handleImageUpload(presignedRes.uploadUrl, file)
          const quill = quillRef?.current?.getEditor();
          if (quill) {
            const range = quill.getSelection(true);
            quill.insertEmbed(range.index, "image", presignedRes.fileUrl);
            quill.setSelection(range.index, 0);
          }
        } catch (error) {
          if (error instanceof Error) {
            alert(error?.message);
          }
        }
      }
    }
  };
};
