import React from "react";
import styled from "styled-components";

interface FormFieldProps {
  label: string;
  name: string;
  value: string;
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  textarea?: boolean;
}

export const FormField: React.FC<FormFieldProps> = ({ label, name, value, placeholder, onChange, textarea }) => (
    <FieldContainer>
      <label>
        {label}
        {textarea ? (
            <textarea name={name} value={value} placeholder={placeholder} onChange={onChange} />
        ) : (
            <input type="text" name={name} value={value} placeholder={placeholder} onChange={onChange} />
        )}
      </label>
    </FieldContainer>
);

export const SubmitButton = styled.button`
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const FieldContainer = styled.div`
  margin-bottom: 10px;

  label {
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }

  input,
  textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
  }

  textarea {
    resize: vertical; 
  }
`;
