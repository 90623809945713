import React from "react";
import styled from "styled-components";
import {useLoading} from "./LoadingContext";

const LoadingOverlay = () => {
  const { isLoading } = useLoading();

  if (!isLoading) return null;

  return (
      <Overlay>
        <Message>Loading...</Message>
      </Overlay>
  );
};

export default LoadingOverlay;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 1); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const Message = styled.div`
  font-size: 24px; 
  color: #000; 
  font-weight: bold;
`;
