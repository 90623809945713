import React from 'react';
import styled from 'styled-components';
import {PitcherDetailRes} from '../../../type/res/PlayerDetailRes';
import RankSampleLowMessage from "../../detail/statbar/RankSampleLowMessage";
import {
  BARREL_DESCRIPTION,
  BARREL_PA_DESCRIPTION,
  CHASE_DESCRIPTION,
  CSW_DESCRIPTION,
  EV_DESCRIPTION,
  HARDHIT_DESCRIPTION,
  ICR_DESCRIPTION,
  IPA_DESCRIPTION,
  WHIFF_DESCRIPTION,
  XERA_DESCRIPTION,
  XWOBA_DESCRIPTION
} from "../../detail/statbar/StatDescriptionConst";
import CompareStatRowItem from "./item/CompareStatRowItem";

interface PitcherCompareStatProps {
  leftPlayer: PitcherDetailRes | null;
  rightPlayer: PitcherDetailRes | null;
}

const PitcherCompareStat = ({ leftPlayer, rightPlayer }: PitcherCompareStatProps) => {
  return (
      <PlayerStatsContainer>
        <Section>
          <RankSampleLowMessage condition={!leftPlayer || !rightPlayer} message="모든 선수를 선택해주세요"/>
          <CompareStatRowItem
              label="xERA"
              leftRank={leftPlayer?.rank?.expectedEra || null}
              leftValue={leftPlayer?.expectedEra?.toFixed(2) || "-"}
              rightRank={rightPlayer?.rank?.expectedEra || null}
              rightValue={rightPlayer?.expectedEra?.toFixed(2) || "-"}
              description={XERA_DESCRIPTION}
          />

          <CompareStatRowItem
              label="SIERA"
              leftRank={leftPlayer?.rank?.siera || null}
              leftValue={leftPlayer?.siera?.toFixed(2) || "-"}
              rightRank={rightPlayer?.rank?.siera || null}
              rightValue={rightPlayer?.siera?.toFixed(2) || "-"}
              description={XERA_DESCRIPTION}
          />

          <CompareStatRowItem
              label="FIP"
              leftRank={leftPlayer?.rank?.fip || null}
              leftValue={leftPlayer?.fip?.toFixed(2) || "-"}
              rightRank={rightPlayer?.rank?.fip || null}
              rightValue={rightPlayer?.fip?.toFixed(2) || "-"}
          />

          <CompareStatRowItem
              label="xwOBAcon"
              leftRank={leftPlayer?.rank?.expectedWobaCon || null}
              leftValue={leftPlayer?.expectedWobaCon?.toFixed(3) || "-"}
              rightRank={rightPlayer?.rank?.expectedWobaCon || null}
              rightValue={rightPlayer?.expectedWobaCon?.toFixed(3) || "-"}
              description={XWOBA_DESCRIPTION}
          />

          <CompareStatRowItem
              label="Whiff %"
              leftRank={leftPlayer?.rank?.whiffPercent || null}
              leftValue={`${leftPlayer?.whiffPercent?.toFixed(1) || "-"}%`}
              rightRank={rightPlayer?.rank?.whiffPercent || null}
              rightValue={`${rightPlayer?.whiffPercent?.toFixed(1) || "-"}%`}
              description={WHIFF_DESCRIPTION}
          />

          <CompareStatRowItem
              label="Chase %"
              leftRank={leftPlayer?.rank?.chasePercent || null}
              leftValue={`${leftPlayer?.chasePercent?.toFixed(1) || "-"}%`}
              rightRank={rightPlayer?.rank?.chasePercent || null}
              rightValue={`${rightPlayer?.chasePercent?.toFixed(1) || "-"}%`}
              description={CHASE_DESCRIPTION}
          />

          <CompareStatRowItem
              label="CSW %"
              leftRank={leftPlayer?.rank?.callStrikePercent || null}
              leftValue={`${leftPlayer?.callStrikePercent?.toFixed(1) || "-"}%`}
              rightRank={rightPlayer?.rank?.callStrikePercent || null}
              rightValue={`${rightPlayer?.callStrikePercent?.toFixed(1) || "-"}%`}
              description={CSW_DESCRIPTION}
          />

          <CompareStatRowItem
              label="EV"
              leftRank={leftPlayer?.rank?.exitVelocity || null}
              leftValue={`${leftPlayer?.exitVelocity?.toFixed(1) || "-"}%`}
              rightRank={rightPlayer?.rank?.exitVelocity || null}
              rightValue={`${rightPlayer?.exitVelocity?.toFixed(1) || "-"}%`}
              description={EV_DESCRIPTION}
          />

          <CompareStatRowItem
              label="Barrel %"
              leftRank={leftPlayer?.rank?.barrelPercent || null}
              leftValue={`${leftPlayer?.barrelPercent?.toFixed(1) || "-"}%`}
              rightRank={rightPlayer?.rank?.barrelPercent || null}
              rightValue={`${rightPlayer?.barrelPercent?.toFixed(1) || "-"}%`}
              description={BARREL_DESCRIPTION}
          />

          <CompareStatRowItem
              label="Barrel/PA"
              leftRank={leftPlayer?.rank?.barrelByPaPercent || null}
              leftValue={leftPlayer?.barrelByPaPercent?.toFixed(1) || "-"}
              rightRank={rightPlayer?.rank?.barrelByPaPercent || null}
              rightValue={rightPlayer?.barrelByPaPercent?.toFixed(1) || "-"}
              description={BARREL_PA_DESCRIPTION}
          />

          <CompareStatRowItem
              label="IPA %"
              leftRank={leftPlayer?.rank?.ipaPercent || null}
              leftValue={leftPlayer?.ipaPercent?.toFixed(1) || "-"}
              rightRank={rightPlayer?.rank?.ipaPercent || null}
              rightValue={rightPlayer?.ipaPercent?.toFixed(1) || "-"}
              description={IPA_DESCRIPTION}
          />

          <CompareStatRowItem
              label="ICR %"
              leftRank={leftPlayer?.rank?.icrPercent || null}
              leftValue={`${leftPlayer?.icrPercent?.toFixed(1) || "-"}%`}
              rightRank={rightPlayer?.rank?.icrPercent || null}
              rightValue={`${rightPlayer?.icrPercent?.toFixed(1) || "-"}%`}
              description={ICR_DESCRIPTION}
          />

          <CompareStatRowItem
              label="HardHit %"
              leftRank={leftPlayer?.rank?.hardHitPercent || null}
              leftValue={`${leftPlayer?.hardHitPercent?.toFixed(1) || "-"}%`}
              rightRank={rightPlayer?.rank?.hardHitPercent || null}
              rightValue={`${rightPlayer?.hardHitPercent?.toFixed(1) || "-"}%`}
              description={HARDHIT_DESCRIPTION}
          />
        </Section>
      </PlayerStatsContainer>
  );
};

export default PitcherCompareStat;

export const PlayerStatsContainer = styled.div`
  margin: 20px 0;
  padding: 0 0px;
`;

export const Section = styled.div`
  margin: 10px 0;
  width: 100%;
`;
