import {useEffect, useState} from 'react';
import useQueryString from "../common/useQueryString";

const DEFAULT_RECORDS_PER_PAGE = 25;

const usePage = (items: any[], recordsPerPage: number = DEFAULT_RECORDS_PER_PAGE) => {
  const [queryPage, setQueryStringPage] = useQueryString("page", "1");
  const [currentPage, setCurrentPage] = useState(Number(queryPage));
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const newQueryPage = currentPage.toString();
    if (queryPage !== newQueryPage) {
      setQueryStringPage(newQueryPage);
    }
  }, [currentPage, queryPage, setQueryStringPage]);

  useEffect(() => {
    setTotalPages(Math.ceil(items.length / recordsPerPage));
  }, [items, recordsPerPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return {
    setCurrentPage,
    page: {
      currentPage,
      totalPages,
      handlePageChange,
    },
    pageSize: recordsPerPage,
  };
};

export default usePage;
export { DEFAULT_RECORDS_PER_PAGE };
