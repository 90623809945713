export const PATH = {
  HOME: '/',
  PLAYER_DETAIL: '/player/detail/:playerId',
  COMPARE_PLAYER: '/compare-players',
  ADMIN: '/admin',
  POST: '/admin/post',
  POST_DETAIL: '/admin/post/detail/:postId',
  POST_EDIT: '/admin/post/edit/:postId',
  POST_NEW: '/admin/post/new',
  GLOSSARY: '/admin/glossary',
  ANNOUNCEMENT: '/admin/announcement',
  FAQ: '/faq',
  LOGIN: '/admin/login',
  CACHE: '/admin/cache',
} as const;
