import React from 'react';
import styled from 'styled-components';

interface CompareRankBarProps {
  rank: number | null | undefined;
  direction: 'left' | 'right';
}

const CompareRankBar = ({ rank, direction }: CompareRankBarProps) => {
  const getColor = (rank: number | null | undefined) => {
    if (rank === undefined || rank === null) return '#d3d3d3';

    let r = 0;
    let g = 0;
    let b = 0;

    if (rank <= 50) {
      const ratio = rank / 50;
      r = Math.round(211 * ratio);
      g = Math.round(211 * ratio);
      b = 255 - Math.round(44 * ratio);
    } else {
      const ratio = (rank - 50) / 50;
      r = 211 + Math.round(44 * ratio);
      g = 211 - Math.round(211 * ratio);
      b = 211 - Math.round(211 * ratio);
    }

    return `rgb(${r}, ${g}, ${b})`;
  };


  const color = getColor(rank);
  const barStyle = {
    backgroundColor: color,
    width: `${rank || 0}%`,
    left: direction === 'right' ? '0' : 'auto',
    right: direction === 'left' ? '0' : 'auto',
    transformOrigin: direction === 'left' ? 'right' : 'left',
  };

  return (
      <BarContainer>
        <Bar rank={rank} style={barStyle}>
          {/*<RankLabel direction={direction} style={{ backgroundColor: color }}>*/}
          {/*  {rank !== undefined ? rank : 'N/A'}*/}
          {/*</RankLabel>*/}
        </Bar>
      </BarContainer>
  );
};

const BarContainer = styled.div`
  height: 15px;
  background-color: lightgrey;
  border-radius: 5px;
  margin: 5px 0;
  overflow: hidden;
  flex-grow: 1;
  position: relative;
`;

const Bar = styled.div<{ rank?: number | null }>`
  height: 100%;
  width: 100%;
  color: white;
  text-align: center;
  border-radius: 5px;
  position: absolute;
`;

export default CompareRankBar;
