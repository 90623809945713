import {useQuery} from "react-query";
import {AnnouncementApi, AnnouncementResponse} from "../../apis/AnnouncementApi";

export const useAnnouncement = () => {

  const { data } = useQuery<AnnouncementResponse[], Error>({
    queryKey: ['announcements'],
    queryFn: AnnouncementApi.getAnnouncements,
    staleTime: 60000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return { announcements: data };
};
