import styled from "styled-components";
import {pageTd, pageTh} from "../../styles/Table";

export const TableContainer = styled.div`
    overflow-x: auto;
    width: 100%;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

export const Th = styled.th<{ $isSorted: boolean }>`
    background-color: ${props => (props.$isSorted ? '#ffffcc' : '#ffffff')};
    ${pageTh}
`;

export const Td = styled.td<{ $isSorted: boolean }>`
    background-color: ${props => (props.$isSorted ? '#ffffcc' : 'transparent')};
    ${pageTd}

`;

export const HeaderRow = styled.tr`
  background-color: #f2f2f2;
`;
