import {useCallback} from 'react';
import useFilter from './useFilter';
import {HitterStatRes} from '../../type/res/HitterStatRes';
import {HitterFilterCriteria} from '../../type/HitterFilterCriteria';
import useQueryString from '../common/useQueryString';

const useHitterFilter = (stats: HitterStatRes[], initialCriteria: HitterFilterCriteria) => {
  const [team, setTeam] = useQueryString('team', initialCriteria.team);
  const [minPlateAppearance, setMinPlateAppearance] = useQueryString('minPlateAppearance', initialCriteria.minPlateAppearance.toString());

  const filterHitters = useCallback((stat: HitterStatRes, criteria: HitterFilterCriteria) => {
    return (
      (!criteria.team || stat.team === criteria.team) &&
      (criteria.minPlateAppearance === undefined || stat.plateAppearance >= criteria.minPlateAppearance)
    );
  }, []);

  const {
    filteredStats,
    filterCriteria,
    handleFilterChange
  } = useFilter<HitterStatRes, HitterFilterCriteria>(stats, {
    team,
    minPlateAppearance: parseInt(minPlateAppearance, 10),
  }, filterHitters);

  const updateFilter = (newCriteria: Partial<HitterFilterCriteria>) => {
    handleFilterChange(newCriteria);
    if (newCriteria.team !== undefined) setTeam(newCriteria.team);
    if (newCriteria.minPlateAppearance !== undefined) setMinPlateAppearance(newCriteria.minPlateAppearance.toString());
  };

  return {
    filteredStats,
    filterCriteria,
    handleFilterChange: updateFilter
  };
};

export default useHitterFilter;
