import React from "react";
import {HitterDetailRes} from "../../../type/res/PlayerDetailRes";
import {DetailTd, DetailTh} from "../../../styles/Table";
import {Table, TableContainer} from "./TotalStatComponent";
import {getBackgroundColor} from "./functions";

interface HitterDetailStatComponentProps {
  stats: HitterDetailRes[];
}

export const HitterTotalStatComponent = ({ stats } : HitterDetailStatComponentProps) => {
  const sortedStats = [...stats].sort((a, b) => a.year - b.year);

  return (
    <TableContainer>
      <Table>
        <thead>
        <tr>
          <DetailTh>연도</DetailTh>
          <DetailTh>팀</DetailTh>
          <DetailTh>나이</DetailTh>
          <DetailTh>타석</DetailTh>
          <DetailTh>AVG</DetailTh>
          <DetailTh>OBP</DetailTh>
          <DetailTh>SLG</DetailTh>
          <DetailTh>OPS</DetailTh>
          <DetailTh>wOBA</DetailTh>
          <DetailTh>EV</DetailTh>
          <DetailTh>sd(LA)</DetailTh>
          <DetailTh>SwSp %</DetailTh>
          <DetailTh>Chase %</DetailTh>
          <DetailTh>Whiff %</DetailTh>
          <DetailTh>CSW %</DetailTh>
          <DetailTh>Zone Judgement</DetailTh>
          <DetailTh>HardHit %</DetailTh>
          <DetailTh>Barrel %</DetailTh>
          <DetailTh>Barrel/PA</DetailTh>
          <DetailTh>ICR %</DetailTh>
          <DetailTh>IPA %</DetailTh>
          <DetailTh>xBA</DetailTh>
          <DetailTh>xwOBAcon</DetailTh>
          <DetailTh>xwOBA</DetailTh>
        </tr>
        </thead>
        <tbody>
        {sortedStats.map((stat, index) => {
          const { rank, ...rest } = stat;
          return (
            <tr key={index}>
              <DetailTd $bgColor="">{rest.year}</DetailTd>
              <DetailTd $bgColor="">{rest.team}</DetailTd>
              <DetailTd $bgColor="">{rest.age}</DetailTd>
              <DetailTd $bgColor="">{rest.plateAppearance}</DetailTd>
              <DetailTd $bgColor="">{rest.avg.toFixed(3)}</DetailTd>
              <DetailTd $bgColor="">{rest.obp.toFixed(3)}</DetailTd>
              <DetailTd $bgColor="">{rest.slg.toFixed(3)}</DetailTd>
              <DetailTd $bgColor="">{rest.ops.toFixed(3)}</DetailTd>
              <DetailTd $bgColor="">{rest.woba.toFixed(3)}</DetailTd>
              <DetailTd $bgColor={getBackgroundColor(rank?.exitVelocity)}>{rest.exitVelocity?.toFixed(1)}</DetailTd>
              <DetailTd $bgColor={getBackgroundColor(rank?.solidLaunchAngle)}>{rest.solidLaunchAngle?.toFixed(1)}</DetailTd>
              <DetailTd $bgColor={getBackgroundColor(rank?.sweetSpotPercent)}>{rest.sweetSpotPercent?.toFixed(1)}</DetailTd>
              <DetailTd $bgColor={getBackgroundColor(rank?.chasePercent)}>{rest.chasePercent?.toFixed(1)}</DetailTd>
              <DetailTd $bgColor={getBackgroundColor(rank?.whiffPercent)}>{rest.whiff?.toFixed(1)}</DetailTd>
              <DetailTd $bgColor={getBackgroundColor(rank?.callStrikePercent)}>{rest.callStrikePercent?.toFixed(1)}</DetailTd>
              <DetailTd $bgColor={getBackgroundColor(rank?.swingDecision)}>{rest.swingDecision}</DetailTd>
              <DetailTd $bgColor={getBackgroundColor(rank?.hardHitPercent)}>{rest.hardHitPercent?.toFixed(1)}</DetailTd>
              <DetailTd $bgColor={getBackgroundColor(rank?.barrelPercent)}>{rest.barrelPercent?.toFixed(1)}</DetailTd>
              <DetailTd $bgColor={getBackgroundColor(rank?.barrelByPaPercent)}>{rest.barrelByPaPercent?.toFixed(1)}</DetailTd>
              <DetailTd $bgColor={getBackgroundColor(rank?.icrPercent)}>{rest.icrPercent?.toFixed(1)}</DetailTd>
              <DetailTd $bgColor={getBackgroundColor(rank?.ipaPercent)}>{rest.ipaPercent?.toFixed(1)}</DetailTd>
              <DetailTd $bgColor={getBackgroundColor(rank?.expectedBattingAverage)}>{rest.expectedBattingAverage?.toFixed(3)}</DetailTd>
              <DetailTd $bgColor={getBackgroundColor(rank?.expectedWobaCon)}>{rest.expectedWobaCon?.toFixed(3)}</DetailTd>
              <DetailTd $bgColor={getBackgroundColor(rank?.expectedWoba)}>{rest.expectedWoba?.toFixed(3)}</DetailTd>
            </tr>
          );
        })}
        </tbody>
      </Table>
    </TableContainer>
  );
};


export default HitterTotalStatComponent;
