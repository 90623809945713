import React, {useState} from 'react';
import styled from 'styled-components';
import {useAnnouncement} from "../../../hooks/common/useAnnouncement";
import ExpandButton from "./ExpandButton";

const Container = styled.div`
  width: 100%;
  margin: 1.5rem 0 0 0;
  padding-left: 10px;
`;

const AnnouncementCard = styled.div`
  background-color: #f1f1f1;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const AnnouncementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AnnouncementTitle = styled.h2`
  margin: 0;
  font-size: 1.25rem;
  color: #333333;
  font-family: 'Jua', sans-serif;
`;


const AnnouncementContent = styled.p`
  margin-top: 8px;
  font-size: 0.9rem;
  white-space: pre-wrap;
  font-family: 'Jua', sans-serif;
  text-align: left;
  color: #444444;
`;

const AnnouncementDateTime = styled.div`
  bottom: 10px;
  left: 10px;
  font-size: 0.75rem;
  font-family: 'Jua', sans-serif;
  color: #777777;
`;

const AnnouncementList: React.FC = () => {
  const {announcements} = useAnnouncement();
  const [expanded, setExpanded] = useState(false);

  if (!announcements) return <p>Loading...</p>;

  return (
      <Container>
        {announcements.length > 0 && (
            <AnnouncementCard>
              <AnnouncementHeader>
                <AnnouncementTitle>공지사항</AnnouncementTitle>
              </AnnouncementHeader>
              <AnnouncementContent>{announcements[0].content}</AnnouncementContent>
              <AnnouncementDateTime>
                {new Date(announcements[0].announcementDateTime).toLocaleString()}
              </AnnouncementDateTime>
              <ExpandButton
                  expanded={expanded}
                  onClick={() => setExpanded(!expanded)}
                  showButton={announcements.length > 1}
              />
            </AnnouncementCard>
        )}

        {expanded && announcements.length > 1 && (
            announcements.slice(1).map((announcement, index) => (
                <AnnouncementCard key={index}>
                  <AnnouncementContent>{announcement.content}</AnnouncementContent>
                  <AnnouncementDateTime>
                    {new Date(announcements[0].announcementDateTime).toLocaleString()}
                  </AnnouncementDateTime>
                </AnnouncementCard>
            ))
        )}
      </Container>
  );
};

export default AnnouncementList;
