import React from 'react';
import styled from 'styled-components';

const StyledSearchBar = styled.input`
    width: 100%;
    padding: 10px 10px;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 2px;
    font-family: 'Jua', sans-serif;

    &::placeholder {
        color: #999;
        font-size: 18px;
    }

    @media (max-width: 900px) {
        font-size: 15px;
        padding: 8px 0;

        &::placeholder {
            font-size: 14px;
        }
    }

    @media (max-width: 480px) {
        font-size: 13px;
        padding: 6px 0;

        &::placeholder {
            font-size: 12px;
        }
    }
`;

interface SearchBarProps {
  keyword: string,
  setKeyword: (keyword: string) => void,
  onFocus: () => void,
  onBlur: () => void
}

const MainSearchBar = ({ keyword, setKeyword, onFocus, onBlur }: SearchBarProps) => {
  return (
    <StyledSearchBar
      type="text"
      placeholder="선수 상세 검색"
      value={keyword}
      onChange={(e) => setKeyword(e.target.value)}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default MainSearchBar;
