import React, {useState} from "react";
import Logo from "../../components/common/header/Logo";
import {HamburgerButton, Header, InternalNavLink, Nav, TopRow} from "../../components/common/header/HeaderStyle";
import {PATH} from "../../constant/Path";

const AdminHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
      <Header>
        <TopRow>
          <Logo/>
          <HamburgerButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {isMenuOpen ? '✕' : '☰'}
          </HamburgerButton>
        </TopRow>
        <Nav $isOpen={isMenuOpen}>
          <InternalNavLink to={PATH.ADMIN}>스탯 업로드</InternalNavLink>
          <InternalNavLink to={PATH.GLOSSARY}>도움말 편집</InternalNavLink>
          <InternalNavLink to={PATH.POST}>게시물 편집</InternalNavLink>
          <InternalNavLink to={PATH.ANNOUNCEMENT}>공지사항 편집</InternalNavLink>
        </Nav>
      </Header>
  )
      ;
}

export default AdminHeader
