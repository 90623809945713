import {useEffect, useMemo, useState} from 'react';

const useFilter = <T, C>(stats: T[], initialCriteria: C, filterFunc: (stat: T, criteria: C) => boolean) => {
  const [filterCriteria, setFilterCriteria] = useState<C>(initialCriteria);
  const [filteredStats, setFilteredStats] = useState<T[]>([]);

  const memoizedFilterFunc = useMemo(() => filterFunc, [filterFunc]);

  useEffect(() => {
    setFilteredStats(stats.filter(stat => memoizedFilterFunc(stat, filterCriteria)));
  }, [filterCriteria, stats, memoizedFilterFunc]);

  const handleFilterChange = (newCriteria: Partial<C>) => {
    setFilterCriteria(prevCriteria => ({ ...prevCriteria, ...newCriteria }));
  };

  return {
    filteredStats,
    filterCriteria,
    handleFilterChange
  };
};

export default useFilter;
