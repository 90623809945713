import {PlayerDetailRes} from "../../../type/res/PlayerDetailRes";
import React from "react";
import PitcherTotalStatComponent from "./PitcherTotalStatComponent";
import styled from "styled-components";
import HitterTotalStatComponent from "./HitterTotalStatComponent";
import {isPitcher} from "../../../functions/common";

interface PlayerDetailStatProps {
  detail: PlayerDetailRes;
}

const TotalStatComponent = ({detail}: PlayerDetailStatProps) => {
  return (
      <>
        <Header>통산 기록</Header>
        {isPitcher(detail.position) ? (
            <PitcherTotalStatComponent stats={detail.pitcherStats}/>
        ) : (
            <HitterTotalStatComponent stats={detail.hitterStats}/>
        )}
      </>
  );
};

export default TotalStatComponent;

export const Header = styled.div`
  width: 100%;
  border-collapse: collapse;
  font-size: 30px;
  margin-top: 50px;
  font-family: jua;

  @media (max-width: 700px) {
    font-size: 22px;
  }
`;

export const TableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
  border-top: 2px dashed #ddd;
  margin-top: 30px;
  font-family: jua;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 19px;
`;
