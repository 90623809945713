import React from 'react';
import styled from 'styled-components';
import {PlayerSearchRes} from "../../../type/res/PlayerSearchRes";

interface ComparePlayerSearchListProps {
  isFocused: boolean;
  players: PlayerSearchRes[];
  onSelectPlayer: (playerId: number) => void;
}

const ComparePlayerSearchList = ({isFocused, players, onSelectPlayer}: ComparePlayerSearchListProps) => {
  if (!isFocused) {
    return <div/>
  }

  if (isFocused && players.length === 0) {
    return <ListContainer><ListItem>해당하는 선수가 존재하지 않습니다.</ListItem></ListContainer>
  }

  return (
      <ListContainer>
        {players.map(player => (
            <ListItem key={player.playerId} onClick={() => onSelectPlayer(player.playerId)}>
              <PlayerName>{player.name}</PlayerName>
              <p>
              <PlayerDetails>
                {player.team}  | {player.position}
              </PlayerDetails>
              </p>
            </ListItem>
        ))}
      </ListContainer>
  );
};

export default ComparePlayerSearchList;

const ListContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: calc(80%); /* SearchInput 아래에 조금 더 떨어지게 설정 */
  width: 100%;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  box-sizing: border-box; /* 패딩과 보더를 크기에 포함 */
`;


const ListItem = styled.li`
  display: flex;
  font-family: jua;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.75rem;
  border-bottom: 1px solid #ddd;
  font-size: 1rem;

  &:last-child {
    border-bottom: none;
  }
`;

const PlayerName = styled.span`
  font-weight: bold;
`;

const PlayerDetails = styled.span`
  color: #666;
`;
