import React from "react";
import styled from "styled-components";
import Button from "../../../components/common/common/Button";

interface AdminButtonProps extends React.ComponentProps<typeof Button> {
  backgroundColor: string;
}

const AdminButton: React.FC<AdminButtonProps> = ({ backgroundColor, ...props }) => {
  return <StyledButton backgroundColor={backgroundColor} {...props} />;
};

export default AdminButton;

const StyledButton = styled(Button)<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: ${({ backgroundColor }) => darkenColor(backgroundColor)};
  }
`;

const darkenColor = (color: string) => {
  const shade = 0.1;
  let colorInt = parseInt(color.slice(1), 16);
  const t = shade < 0 ? 0 : 255;
  const p = shade < 0 ? shade * -1 : shade;
  const R = colorInt >> 16;
  const G = (colorInt >> 8) & 0x00ff;
  const B = colorInt & 0x0000ff;
  const newColor =
      "#" +
      (
          0x1000000 +
          (Math.round((t - R) * p) + R) * 0x10000 +
          (Math.round((t - G) * p) + G) * 0x100 +
          (Math.round((t - B) * p) + B)
      )
      .toString(16)
      .slice(1);
  return newColor;
};
