import {useMutation, UseMutationOptions, UseMutationResult} from "react-query";
import {renewHitterCache, renewPitcherCache} from "../../apis/CacheRenewApi";
import {AxiosError} from "axios";
import {ErrorResponse} from "../../../common/Error";

const useCacheRenewal = (
    renewCacheFn: (year: number) => Promise<void>,
    options?: UseMutationOptions<void, AxiosError<ErrorResponse>, number>
): UseMutationResult<void, AxiosError<ErrorResponse>, number> => {
  return useMutation(renewCacheFn, options);
};

export const useRenewHitterCache = (options?: UseMutationOptions<void, AxiosError<ErrorResponse>, number>) =>
    useCacheRenewal(renewHitterCache, options);

export const useRenewPitcherCache = (options?: UseMutationOptions<void, AxiosError<ErrorResponse>, number>) =>
    useCacheRenewal(renewPitcherCache, options);
