import {useLocation, useNavigate} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";

const parseQueryValues = (search: string, initialValues: Record<string, string>) => {
  const queryParams = new URLSearchParams(search);
  return Object.keys(initialValues).reduce((acc, key) => {
    const valueFromQuery = queryParams.get(key);
    acc[key] = valueFromQuery !== null ? valueFromQuery : initialValues[key];
    return acc;
  }, {} as Record<string, string>);
};

export const useMultipleQueryStrings = (initialValues: Record<string, string>) => {
  const location = useLocation();
  const navigate = useNavigate();

  const getQueryValues = useCallback(() => parseQueryValues(location.search, initialValues), [location.search, initialValues]);

  const [queryValues, setQueryValues] = useState(getQueryValues);

  const handleValuesChange = useCallback((newValues: Record<string, string>) => {
    const newQueryParams = new URLSearchParams(location.search);
    Object.keys(newValues).forEach((key) => {
      newQueryParams.set(key, newValues[key]);
    });
    navigate({ search: newQueryParams.toString() }, { replace: true });
  }, [navigate, location.search]);

  useEffect(() => {
    const newQueryValues = getQueryValues();
    setQueryValues(prevQueryValues => {
      if (JSON.stringify(prevQueryValues) !== JSON.stringify(newQueryValues)) {
        return newQueryValues;
      }
      return prevQueryValues;
    });
  }, [location.search, getQueryValues]);

  return [queryValues, handleValuesChange] as const;
};
