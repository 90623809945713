import styled from "styled-components";


interface RankSampleLoweMessageProps {
  condition: boolean;
  message: string
}


const RankSampleLowMessage = ({condition, message} : RankSampleLoweMessageProps) => {
  if (!condition) {
    return null;
  }
  return <RankSampleLowDiv>{message}</RankSampleLowDiv>;
}

export default RankSampleLowMessage

export const RankSampleLowDiv = styled.div`
  color: red;
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: hanna;
`;
