import React from 'react';
import styled from 'styled-components';
import DetailDropdown from "./DetailDropdown";

interface DetailHeaderComponentProps {
  selectedYear: number | string;
  handleYearChange: (value: number | string) => void;
  years: { value: number | string; label: number | string }[];
}

const DetailHeaderComponent = ({selectedYear, handleYearChange, years, children}: React.PropsWithChildren<DetailHeaderComponentProps>) => {
  return (
      <HeaderWrapper>
        {children && <ButtonWrapper>{children}</ButtonWrapper>}
        <TitleWrapper>
          <DetailDropdown value={selectedYear} onChange={handleYearChange} options={years}/>
          <Title>시즌</Title>
        </TitleWrapper>
      </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Title = styled.h2`
  font-size: 22px;
  font-family: jua;
  font-weight: normal;
`;

DetailHeaderComponent.Button = ({children}: {children: React.ReactNode}) => {
  return <>{children}</>;
};

export default DetailHeaderComponent;
