import React from 'react';
import FilterBox from './FilterBox';
import {TEAM_FILTERS} from '../../type/FilterConst';

interface TeamFilterProps {
  team: string;
  onTeamChange: (team: string) => void;
}

const TeamFilter = ({ team, onTeamChange }: TeamFilterProps) => {
  const handleTeamChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onTeamChange(event.target.value);
  };

  return (
    <FilterBox
      id="team-filter"
      value={team}
      onChange={handleTeamChange}
      options={TEAM_FILTERS}
      prefix="소속 팀"
    />
  );
};

export default TeamFilter;
