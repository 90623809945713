import React from 'react';
import {TableContainer} from './common/StatTable';
import HitterStatPage from './page/HitterStatPage';
import PitcherStatPage from './page/PitcherStatPage';
import useQueryString from "../hooks/common/useQueryString";
import {isPitcher} from "../functions/common";

const StatPageComponent = () => {

  const [position, setPosition] = useQueryString('position', '타자', true);


  const handlePositionChange = (newPosition: string): void => {
    setPosition(newPosition);
  };

  return (
    <div className="App">
      <TableContainer>
        {isPitcher(position) ? (
            <PitcherStatPage position={position} onPositionChange={handlePositionChange} />
        ) : (
          <HitterStatPage position={position} onPositionChange={handlePositionChange} />
        )}
      </TableContainer>
    </div>
  );
}

export default StatPageComponent;
