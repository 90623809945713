import React from 'react';
import FilterBox from './FilterBox';
import {POSITION_OPTIONS} from '../../type/FilterConst';

interface PositionFilterProps {
  position: string;
  onPositionChange: (position: string) => void;
}

const PositionFilter = ({ position, onPositionChange }: PositionFilterProps) => {
  const handlePositionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onPositionChange(event.target.value);
  };

  return (
    <FilterBox
      id="position-filter"
      value={position}
      onChange={handlePositionChange}
      options={POSITION_OPTIONS}
    />
  );
};

export default PositionFilter;
