import {useEffect, useState} from 'react';
import {HitterDetailRes, PitcherDetailRes} from "../../type/res/PlayerDetailRes";
import {isPitcher} from "../../functions/common";

const useRankBarYear = (position: string, hitters: HitterDetailRes[], pitchers: PitcherDetailRes[]) => {
  const [selectedYear, setSelectedYear] = useState<number | string>('');
  const data = isPitcher(position) ? pitchers : hitters;

  useEffect(() => {
    if (data.length > 0) {
      setSelectedYear(data[0].year);
    }
  }, [data]);

  const handleYearChange = (value: number | string) => {
    setSelectedYear(value);
  };

  const sortedData = [...data].sort((a, b) => b.year - a.year);
  const years = sortedData.map(player => ({ value: player.year, label: player.year }));

  return { selectedYear, handleYearChange, years };
};

export default useRankBarYear;
