import {useMutation, useQuery, useQueryClient} from "react-query";
import {addGlossary, deleteGlossary, getAllGlossaries, GlossaryRequest, updateGlossary} from "../../apis/GlossaryAdminApi";
import {GlossaryResponse} from "../../../apis/GlossaryApi";
import {useState} from "react";

export const useGlossaryAdmin = () => {
  const {glossaries, error, isLoading} = useFetchGlossaries();
  const {addGlossary} = useAddGlossary();
  const {updateGlossary} = useUpdateGlossary();
  const {deleteGlossary} = useDeleteGlossary();

  const [newGlossary, setNewGlossary] = useState<GlossaryRequest>({name: "", description: ""});

  const handleAddGlossary = () => {
    addGlossary(newGlossary);
    setNewGlossary({name: "", description: ""});
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {name, value} = e.target;
    setNewGlossary({...newGlossary, [name]: value});
  };

  return {
    glossaries,
    error,
    isLoading,
    newGlossary,
    handleInputChange,
    handleAddGlossary,
    updateGlossary,
    deleteGlossary,
  };
};

const useFetchGlossaries = () => {
  const {data, error, isLoading, ...rest} = useQuery<GlossaryResponse[], Error>({
    queryKey: ['glossaries'],
    queryFn: getAllGlossaries,
    staleTime: 10000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return {glossaries: data, error, isLoading, ...rest};
};

const useAddGlossary = () => {
  const queryClient = useQueryClient();
  const {mutate, ...rest} = useMutation({
    mutationFn: (newGlossary: GlossaryRequest) => addGlossary(newGlossary),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['glossaries']});
      alert('도움말 생성 성공!');
    },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.msg || '도움말 생성에 실패했습니다.';
      alert(errorMessage);
    },
  });

  return {addGlossary: mutate, ...rest};
};

const useUpdateGlossary = () => {
  const queryClient = useQueryClient();
  const {mutate, ...rest} = useMutation({
    mutationFn: ({id, glossary}: { id: number, glossary: GlossaryRequest }) =>
        updateGlossary(id, glossary),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['glossaries']});
      alert('도움말 수정 성공!');
    },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.msg || '도움말 수정에 실패했습니다.';
      alert(errorMessage);
    },
  });

  return {updateGlossary: mutate, ...rest};
};

const useDeleteGlossary = () => {
  const queryClient = useQueryClient();
  const {mutate, ...rest} = useMutation({
    mutationFn: (id: number) => deleteGlossary(id),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['glossaries']});
      alert('삭제 완료');
    },
    onError: () => {
      alert('Failed to delete glossary.');
    },
  });

  return {deleteGlossary: mutate, ...rest};
};
