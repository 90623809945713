import {client} from "./ApiClient";
import {PlayerDetailRes} from "../type/res/PlayerDetailRes";


export class PlayerDetailApi {
  static async getDetail(playerId: number): Promise<PlayerDetailRes> {
    let response = await client.get<PlayerDetailRes>(`/players/${playerId}`)
    return response.data
  }
}
