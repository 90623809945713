import styled from "styled-components";
import {NavLink as RouterNavLink} from "react-router-dom";
import BaseNavLink from "../../../styles/Link";

interface NavProps {
  $isOpen: boolean;
}

export const Header = styled.header`
  border-bottom: 1px solid #ddd;
  margin: 1.5rem 0 0 0;
  background-color: #fff;
`;

export const TopRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
`;

export const HamburgerButton = styled.button`
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;

    @media (max-width: 768px) {
        display: block;
    }
`;

export const Nav = styled.nav<NavProps>`
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    padding: 30px 20px;
    border-top: 1px solid #ddd;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        display: ${(props) => (props.$isOpen ? 'flex' : 'none')};
    }
`;

export const InternalNavLink = styled(RouterNavLink)`
    ${BaseNavLink}
`;

export const ExternalNavLink = styled.a`
  ${BaseNavLink}
`;
