import {POSITIONS, PositionType} from '../../constant/PositionConstants';
import useSearch from './useSearch';
import {useState} from "react";


interface usePositionSearchProps {
  position: PositionType
}

const usePositionSearch = ({position}: usePositionSearchProps) => {
  const {keyword, setKeyword, players} = useSearch("");
  const [isFocused, setIsFocused] = useState(false);

  const positionPlayers = players.filter(player => {
    if (position === POSITIONS.PITCHER) {
      return player.position === POSITIONS.PITCHER
    } else {
      return player.position !== POSITIONS.PITCHER
    }
  });

  return {keyword, setKeyword, positionPlayers, isFocused, setIsFocused}
};

export default usePositionSearch;
