import React, {useEffect} from "react";
import {PositionType} from "../constant/PositionConstants";
import CompareSearch from "../components/compare/search/CompareSearch";
import usePlayerCompare from "../hooks/compare/usePlayerCompareDetail";
import PitcherCompareTotalStatBar from "../components/compare/bar/PitcherCompareTotalStatBar";
import {Container, LeftSearchContainer, RightSearchContainer, SearchContainer} from "./HitterComparePage";

interface PitcherComparePageProps {
  playerId?: number
  position: PositionType
}

const PitcherComparePage = ({playerId, position} : PitcherComparePageProps) => {

  const {
    handlePlayerSelect: handleLeftPlayerSelect,
    setPlayerId,
    data: leftPlayerDetail,
    isLoading: isLoadingLeft,
  } = usePlayerCompare();

  const {
    handlePlayerSelect: handleRightPlayerSelect,
    data: rightPlayerDetail,
    isLoading: isLoadingRight,
  } = usePlayerCompare();

  useEffect(() => {
    if (playerId) {
      setPlayerId(Number(playerId));
    }
  }, [playerId, setPlayerId]);

  return (
      <Container>
        <SearchContainer>
          <LeftSearchContainer>
            <CompareSearch position={position} onSelectPlayer={handleLeftPlayerSelect}/>
          </LeftSearchContainer>
          <RightSearchContainer>
            <CompareSearch position={position} onSelectPlayer={handleRightPlayerSelect}/>
          </RightSearchContainer>
        </SearchContainer>

        {(!isLoadingLeft || !isLoadingRight) && (leftPlayerDetail || rightPlayerDetail) ? (
            <PitcherCompareTotalStatBar
                leftPlayerDetail={leftPlayerDetail}
                rightPlayerDetail={rightPlayerDetail}
            />
        ) : (
            <p></p>
        )}
      </Container>
  );
};

export default PitcherComparePage;
