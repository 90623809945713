import {PitcherStatRes} from '../../type/pitcher/PitcherStatRes';
import {PitcherStatApi} from '../../apis/PitcherStatApi';
import usePitcherFilter from '../filter/usePitcherFilter';
import {PitcherFilterCriteria} from '../../type/pitcher/PitcherFilterCriteria';
import useStats from "./useStat";

const usePitcherStats = () => {
  return useStats<PitcherStatRes, PitcherFilterCriteria>(
    PitcherStatApi.getStats,
    'pitcherStats',
    usePitcherFilter,
    { team: '', innings: 30 },
    'innings'
  );
};

export default usePitcherStats;
