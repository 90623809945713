import React from "react";
import styled from "styled-components";

interface EditableFieldProps {
  label: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  textarea?: boolean;
}

const EditableField = ({ label, name, value, onChange, textarea }: EditableFieldProps) => (
    <FieldContainer>
      <label>
        {label}
        {textarea ? (
            <StyledTextarea name={name} value={value} onChange={onChange} />
        ) : (
            <input type="text" name={name} value={value} onChange={onChange} />
        )}
      </label>
    </FieldContainer>
);

export default EditableField;

const FieldContainer = styled.div`
  margin-bottom: 10px;

  label {
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }

  input,
  textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
  }
`;

const StyledTextarea = styled.textarea`
  resize: vertical;
  white-space: pre-wrap;
`;
