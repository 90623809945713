import {useParams} from 'react-router-dom';
import {PlayerDetailApi} from '../../apis/PlayerDetailApi';
import {PlayerDetailRes} from '../../type/res/PlayerDetailRes';
import {useQuery} from "react-query";

const usePlayerDetailQuery = () => {
  const { playerId } = useParams<Record<string, string | undefined>>();

  const numberPlayerId = Number(playerId);
  const query = useQuery<PlayerDetailRes, Error>(
    ['playerDetail', numberPlayerId],
    () => PlayerDetailApi.getDetail(numberPlayerId),
    {
      enabled: numberPlayerId > 0,
      retry: false,
      staleTime: 10 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      onError: () => {
      },
    }
  );

  return { ...query, playerId: numberPlayerId };
};

export default usePlayerDetailQuery;
