import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import WriteEditor from "./WirteEditor";
import {useFetchPostDetail, useUpdatePost} from "../../hooks/query/usePost";
import ConfirmButton from "../../styles/ConfirmButton";
import EditableTitleComponent from "./EditablePostTitle";
import {PATH} from "../../../constant/Path";

const EditPost = () => {
  const { postId } = useParams<{ postId: string }>();
  const navigate = useNavigate();
  const { postDetail, error, isLoading } = useFetchPostDetail(Number(postId));
  const { updatePost } = useUpdatePost();

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  useEffect(() => {
    if (postDetail) {
      setTitle(postDetail.title);
      setContent(postDetail.content);
    }
  }, [postDetail]);

  const handleSubmit = async () => {
    if (postId) {
      await updatePost({ postId: Number(postId), post: { title, content } });
      navigate(PATH.POST_DETAIL.replace(':postId', postId.toString()));
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading post: {error.message}</div>;
  }

  return (
      <div>
        <EditableTitleComponent title={title} setTitle={setTitle} />
        <WriteEditor content={content} onChange={setContent} />
        <ConfirmButton title="완료" backgroundColor={"#28a745"} onClick={handleSubmit}/>
        <ConfirmButton title="이전" backgroundColor={"#3f3f41"} onClick={handleBack}/>
      </div>
  );
};

export default EditPost;
