import React from 'react';
import TooltipWithState from "../../../common/CustomTooltip";
import styled from "styled-components";
import CompareRankBar from "./CompareRankBar";

interface CompareStatRowItemProps {
  label: string;
  leftRank: number | null | undefined;
  rightRank: number | null | undefined;
  leftValue: string | number | null | undefined;
  rightValue: string | number | null | undefined;
  description?: string;
}

const CompareStatRowItem = ({ label, leftRank, rightRank, leftValue, rightValue, description }: CompareStatRowItemProps) => {
  return (
      <StatRow>
        <StatValue>{leftValue}</StatValue>
        <CompareRankBar rank={leftRank} direction="left" />
        <TooltipWithState description={description || ''}>
          <StatLabel>
            {label}
          </StatLabel>
        </TooltipWithState>
        <CompareRankBar rank={rightRank} direction="right" />
        <StatValue>{rightValue}</StatValue>
      </StatRow>
  );
};

export default CompareStatRowItem;

export const StatRow = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
  border-bottom: 1px solid #ccc;
  font-family: jua;
  position: relative;
`;

export const StatLabel = styled.div`
  width: 80px;
  font-size: 16px;
  text-align: center;
  position: relative;
  cursor: pointer;

  @media (max-width: 720px) {
    font-size: 12px;
  }
  
`;

export const StatValue = styled.div`
  margin-left: 10px;
  font-weight: bold;
  min-width: 50px;
  font-size: 14px;
  text-align: center;


  @media (max-width: 720px) {
    font-size: 12px;
  }
`;
