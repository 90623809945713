import React, {useState} from "react";
import {AnnouncementAdminResponse, AnnouncementRequest} from "../../apis/AnnouncementAdminApi";
import EditableItem from "../../styles/EditableItem";
import EditableField from "../../styles/EditableField";
import styled from "styled-components";
import TextDisplay from "../../styles/TextDisplay";

interface EditableAnnouncementProps {
  announcement: AnnouncementAdminResponse;
  updateAnnouncement: (data: { id: number; announcement: AnnouncementRequest }) => void;
  deleteAnnouncement: (id: number) => void;
}

const EditableAnnouncementItem = ({ announcement, updateAnnouncement, deleteAnnouncement }: EditableAnnouncementProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedAnnouncement, setEditedAnnouncement] = useState<AnnouncementRequest>({
    content: announcement.content,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setEditedAnnouncement({ ...editedAnnouncement, [name]: value });
  };

  const handleSave = () => {
    updateAnnouncement({ id: announcement.id, announcement: editedAnnouncement });
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedAnnouncement({ content: announcement.content });
    setIsEditing(false);
  };

  return (
      <EditableItem
          isEditing={isEditing}
          onSave={handleSave}
          onCancel={handleCancel}
          onDelete={() => deleteAnnouncement(announcement.id)}
          toggleEdit={() => setIsEditing(true)}
      >
        {isEditing ? (
            <EditableField
                label="공지 내용"
                name="content"
                value={editedAnnouncement.content}
                onChange={handleInputChange}
                textarea
            />
        ) : (
            <>
              <h2>공지 내용</h2>
              <TextDisplay text={announcement.content}/>
              <LastUpdated>
                최근 수정일: {new Date(announcement.announcementDateTime).toLocaleString()}
              </LastUpdated>
            </>
        )}
      </EditableItem>
  );
};

export default EditableAnnouncementItem;

const LastUpdated = styled.div`
  margin-top: 10px;
  font-size: 0.875rem;
  color: #888888;
`;
