import React from "react";
import styled from "styled-components";
import {useAnnouncementAdmin} from "../../hooks/query/useAnnouncement";
import {FormField, SubmitButton} from "../../styles/FormField";
import EditableAnnouncementItem from "./EditableAnnouncementItem";
import {AnnouncementAdminResponse} from "../../apis/AnnouncementAdminApi";

const AnnouncementAdminComponent = () => {
  const {
    announcements,
    error,
    isLoading,
    newAnnouncement,
    handleInputChange,
    handleAddAnnouncement,
    updateAnnouncement,
    deleteAnnouncement,
  } = useAnnouncementAdmin();

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>로딩 실패</div>;

  return (
      <Container>
        <h1>공지사항 관리</h1>
        <Form>
          <FormField
              label="내용"
              name="content"
              value={newAnnouncement.content}
              placeholder="공지 내용을 적어주세요"
              onChange={handleInputChange}
              textarea
          />
          <SubmitButton type="button" onClick={handleAddAnnouncement}>
            등록
          </SubmitButton>
        </Form>
        <AnnouncementList>
          {announcements?.map((announcement : AnnouncementAdminResponse) => (
              <EditableAnnouncementItem
                  key={announcement.id}
                  announcement={announcement}
                  updateAnnouncement={updateAnnouncement}
                  deleteAnnouncement={deleteAnnouncement}
              />
          ))}
        </AnnouncementList>
      </Container>
  );
};

export default AnnouncementAdminComponent;

const Container = styled.div`
  padding: 20px;
  background-color: #f9f9f9;

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AnnouncementList = styled.div`
  margin-top: 20px;
`;
