import React from 'react';
import styled from 'styled-components';
import {PageProps} from '../../type/Page';

const PaginationComponent = ({ paginationProps }: { paginationProps: PageProps }) => {
  const { totalPages, currentPage, handlePageChange } = paginationProps;

  const pagesPerBlock = 5;
  const currentBlock = Math.ceil(currentPage / pagesPerBlock);
  const totalBlocks = Math.ceil(totalPages / pagesPerBlock);

  const startPage = (currentBlock - 1) * pagesPerBlock + 1;
  const endPage = Math.min(currentBlock * pagesPerBlock, totalPages);

  const handleFirstPage = () => handlePageChange(1);
  const handleLastPage = () => handlePageChange(totalPages);
  const handlePrevBlock = () => handlePageChange(startPage - 1);
  const handleNextBlock = () => handlePageChange(endPage + 1);

  return (
      <PaginationContainer>
        <PaginationButton onClick={handleFirstPage} disabled={currentPage === 1}>
          처음으로
        </PaginationButton>
        <PaginationButton onClick={handlePrevBlock} disabled={currentBlock === 1}>
          이전
        </PaginationButton>
        {Array.from({ length: endPage - startPage + 1 }, (_, i) => (
            <PaginationButton
                key={startPage + i}
                onClick={() => handlePageChange(startPage + i)}
                disabled={currentPage === startPage + i}
            >
              {startPage + i}
            </PaginationButton>
        ))}
        <PaginationButton onClick={handleNextBlock} disabled={currentBlock === totalBlocks}>
          다음
        </PaginationButton>
        <PaginationButton onClick={handleLastPage} disabled={currentPage === totalPages}>
          마지막으로
        </PaginationButton>
      </PaginationContainer>
  );
};

export default PaginationComponent;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

const PaginationButton = styled.button`
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
