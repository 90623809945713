import {client} from "./ApiClient";

export const getPresignedUrl = async (fileName: string) => {
  const response = await client.get<PresignedUrl>('/admin/post/image/request-url', {
    params: { fileName: fileName }
  });
  return response.data;
}

export interface PresignedUrl {
  uploadUrl: string;
  fileUrl: string;
}
