import {GlossaryApi, GlossaryResponse} from "../../apis/GlossaryApi";
import {useQuery} from "react-query";

export const useGlossaries = () => {

  const { data } = useQuery<GlossaryResponse[], Error>({
    queryKey: ['glossaries'],
    queryFn: GlossaryApi.getGlossaries,
    staleTime: 60000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return { glossaries: data };
};
