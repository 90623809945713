import React from 'react';
import {Th} from '../common/StatTable';

interface SortableColumn<T> {
  header: string;
  field: keyof T | 'index';
}

interface SortProps<T> {
  column: SortableColumn<T>;
  sortField: keyof T;
  sortOrder: 'asc' | 'desc';
  handleSort: (field: keyof T) => void;
}

const CommonSort = <T,>({ column, sortField, sortOrder, handleSort }: SortProps<T>) => {
  if (column.field === 'playerId') return null;
  const isSortable = column.field !== 'index';
  const isSorted = column.field === sortField;

  return (
    <Th $isSorted={isSorted} onClick={() => isSortable && handleSort(column.field as keyof T)}>
      {column.header}
      {isSortable && isSorted && (sortOrder === 'asc' ? ' ▲' : ' ▼')}
    </Th>
  );
};

export default CommonSort;
