import React from 'react';
import ErrorBoundary from "../common/ErrorBoundary";
import PaginationComponent from "../common/PaginationComponent";
import PositionFilter from "../filter/PositionFilter";
import {FilterContainer} from "../common/Filter";
import {PageProps} from "../../type/Page";
import {StatSortProps} from "../../type/Sort";
import styled from "styled-components";

interface StatsPageProps<T, F> {
  children: (props: StatResult<T, F>) => React.ReactNode;
  useStats: () => StatResult<T, F>;
  position: string;
  onPositionChange: (position: string) => void;
}

interface StatResult<T, F> {
  stats: T[];
  error: string | null;
  page: PageProps
  pageSize: number;
  handleFilterChange: (newCriteria: Partial<F>) => void;
  filterCriteria: F;
  year: number;
  setYear: (year: number) => void;
  statSort: StatSortProps<T>
}


const StatsPage = <T, F>({ children, useStats, position, onPositionChange }: StatsPageProps<T, F>) => {
  const statsProps = useStats();

  return (
      <ErrorBoundary>
        <StatContainer className="App">
          <FilterContainer>
            <PositionFilter position={position} onPositionChange={onPositionChange} />
          </FilterContainer>
          {children(statsProps)}
          <PaginationComponent paginationProps={statsProps.page}/>
        </StatContainer>
      </ErrorBoundary>
  );
};

export default StatsPage;

const StatContainer = styled.div`
  padding: 10px 20px;
`;
