import React from 'react';
import styled from 'styled-components';
import {HitterDetailRes} from '../../../type/res/PlayerDetailRes';
import RankSampleLowMessage from "../../detail/statbar/RankSampleLowMessage";
import {
  BARREL_DESCRIPTION,
  CHASE_DESCRIPTION,
  CSW_DESCRIPTION,
  EV_DESCRIPTION,
  HARDHIT_DESCRIPTION,
  ICR_DESCRIPTION,
  IPA_DESCRIPTION,
  SD_LA_DESCRIPTION,
  SWSP_DESCRIPTION,
  WHIFF_DESCRIPTION,
  XBA_DESCRIPTION,
  XWOBA_CON_DESCRIPTION,
  XWOBA_DESCRIPTION,
  ZONE_JUDGEMENT_DESCRIPTION
} from "../../detail/statbar/StatDescriptionConst";
import CompareStatRowItem from "./item/CompareStatRowItem";

interface HitterCompareStatProps {
  leftPlayer: HitterDetailRes | null;
  rightPlayer: HitterDetailRes | null;
}

const HitterCompareStat = ({ leftPlayer, rightPlayer }: HitterCompareStatProps) => {
  return (
      <PlayerStatsContainer>
        <Section>
          <RankSampleLowMessage condition={!leftPlayer || !rightPlayer} message="모든 선수를 선택해주세요"/>
          <CompareStatRowItem
              label="xwOBA"
              leftRank={leftPlayer?.rank?.expectedWoba || null}
              leftValue={leftPlayer?.expectedWoba?.toFixed(3) || "-"}
              rightRank={rightPlayer?.rank?.expectedWoba || null}
              rightValue={rightPlayer?.expectedWoba?.toFixed(3) || "-"}
              description={XWOBA_DESCRIPTION}
          />

          <CompareStatRowItem
              label="xwOBAcon"
              leftRank={leftPlayer?.rank?.expectedWobaCon || null}
              leftValue={leftPlayer?.expectedWobaCon?.toFixed(3) || "-"}
              rightRank={rightPlayer?.rank?.expectedWobaCon || null}
              rightValue={rightPlayer?.expectedWobaCon?.toFixed(3) || "-"}
              description={XWOBA_CON_DESCRIPTION}
          />

          <CompareStatRowItem
              label="xBA"
              leftRank={leftPlayer?.rank?.expectedBattingAverage || null}
              leftValue={leftPlayer?.expectedBattingAverage?.toFixed(3) || "-"}
              rightRank={rightPlayer?.rank?.expectedBattingAverage || null}
              rightValue={rightPlayer?.expectedBattingAverage?.toFixed(3) || "-"}
              description={XBA_DESCRIPTION}
          />

          <CompareStatRowItem
              label="EV"
              leftRank={leftPlayer?.rank?.exitVelocity || null}
              leftValue={leftPlayer?.exitVelocity || "-"}
              rightRank={rightPlayer?.rank?.exitVelocity || null}
              rightValue={rightPlayer?.exitVelocity || "-"}
              description={EV_DESCRIPTION}
          />

          <CompareStatRowItem
              label="Barrel %"
              leftRank={leftPlayer?.rank?.barrelPercent || null}
              leftValue={`${leftPlayer?.barrelPercent?.toFixed(1) || "-"}%`}
              rightRank={rightPlayer?.rank?.barrelPercent || null}
              rightValue={`${rightPlayer?.barrelPercent?.toFixed(1) || "-"}%`}
              description={BARREL_DESCRIPTION}
          />

          <CompareStatRowItem
              label="HardHit %"
              leftRank={leftPlayer?.rank?.hardHitPercent || null}
              leftValue={`${leftPlayer?.hardHitPercent?.toFixed(1) || "-"}%`}
              rightRank={rightPlayer?.rank?.hardHitPercent || null}
              rightValue={`${rightPlayer?.hardHitPercent?.toFixed(1) || "-"}%`}
              description={HARDHIT_DESCRIPTION}
          />

          <CompareStatRowItem
              label="ICR %"
              leftRank={leftPlayer?.rank?.icrPercent || null}
              leftValue={`${leftPlayer?.icrPercent?.toFixed(1) || "-"}%`}
              rightRank={rightPlayer?.rank?.icrPercent || null}
              rightValue={`${rightPlayer?.icrPercent?.toFixed(1) || "-"}%`}
              description={ICR_DESCRIPTION}
          />

          <CompareStatRowItem
              label="IPA %"
              leftRank={leftPlayer?.rank?.ipaPercent || null}
              leftValue={`${leftPlayer?.ipaPercent?.toFixed(1) || "-"}%`}
              rightRank={rightPlayer?.rank?.ipaPercent || null}
              rightValue={`${rightPlayer?.ipaPercent?.toFixed(1) || "-"}%`}
              description={IPA_DESCRIPTION}
          />

          <CompareStatRowItem
              label="SwSp %"
              leftRank={leftPlayer?.rank?.sweetSpotPercent || null}
              leftValue={`${leftPlayer?.sweetSpotPercent?.toFixed(1) || "-"}%`}
              rightRank={rightPlayer?.rank?.sweetSpotPercent || null}
              rightValue={`${rightPlayer?.sweetSpotPercent?.toFixed(1) || "-"}%`}
              description={SWSP_DESCRIPTION}
          />

          <CompareStatRowItem
              label="sd(LA)"
              leftRank={leftPlayer?.rank?.solidLaunchAngle || null}
              leftValue={leftPlayer?.solidLaunchAngle?.toFixed(1) || "-"}
              rightRank={rightPlayer?.rank?.solidLaunchAngle || null}
              rightValue={rightPlayer?.solidLaunchAngle?.toFixed(1) || "-"}
              description={SD_LA_DESCRIPTION}
          />

          <CompareStatRowItem
              label="Zone Judgement"
              leftRank={leftPlayer?.rank?.swingDecision || null}
              leftValue={leftPlayer?.swingDecision || "-"}
              rightRank={rightPlayer?.rank?.swingDecision || null}
              rightValue={rightPlayer?.swingDecision || "-"}
              description={ZONE_JUDGEMENT_DESCRIPTION}
          />

          <CompareStatRowItem
              label="Chase %"
              leftRank={leftPlayer?.rank?.chasePercent || null}
              leftValue={`${leftPlayer?.chasePercent?.toFixed(1) || "-"}%`}
              rightRank={rightPlayer?.rank?.chasePercent || null}
              rightValue={`${rightPlayer?.chasePercent?.toFixed(1) || "-"}%`}
              description={CHASE_DESCRIPTION}
          />

          <CompareStatRowItem
              label="Whiff %"
              leftRank={leftPlayer?.rank?.whiffPercent || null}
              leftValue={`${leftPlayer?.whiff?.toFixed(1) || "-"}%`}
              rightRank={rightPlayer?.rank?.whiffPercent || null}
              rightValue={`${rightPlayer?.whiff?.toFixed(1) || "-"}%`}
              description={WHIFF_DESCRIPTION}
          />

          <CompareStatRowItem
              label="CSW %"
              leftRank={leftPlayer?.rank?.callStrikePercent || null}
              leftValue={`${leftPlayer?.callStrikePercent?.toFixed(1) || "-"}%`}
              rightRank={rightPlayer?.rank?.callStrikePercent || null}
              rightValue={`${rightPlayer?.callStrikePercent?.toFixed(1) || "-"}%`}
              description={CSW_DESCRIPTION}
          />
        </Section>
      </PlayerStatsContainer>
  );
};

export default HitterCompareStat;

export const PlayerStatsContainer = styled.div`
  margin: 20px 0;
  padding: 0 0px;
`;

export const Section = styled.div`
  margin: 10px 0;
  width: 100%;
`;
