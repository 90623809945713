import React from "react";
import styled from "styled-components";
import {PositionType} from "../../../constant/PositionConstants";

interface SegmentedControlProps {
  activeOption: PositionType;
  options: { label: string; value: PositionType }[];
  onOptionChange: (position: PositionType) => void;
}

const SegmentedControl = ({ activeOption, options, onOptionChange }: SegmentedControlProps) => {
  return (
      <SegmentedControlWrapper>
        <SegmentedControlContainer>
          {options.map((option) => (
              <Segment
                  key={option.value}
                  $isActive={activeOption === option.value}
                  onClick={() => onOptionChange(option.value)}
              >
                {option.label}
              </Segment>
          ))}
        </SegmentedControlContainer>
      </SegmentedControlWrapper>
  );
};

export default SegmentedControl;

const SegmentedControlWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  width: 100%;
`;

const SegmentedControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 37.5rem;
  background-color: #f1f1f1;
  border-radius: 0.5rem;
`;

const Segment = styled.button<{ $isActive: boolean }>`
  flex-grow: 1;
  padding: 20px 40px;
  border: none;
  outline: none;
  background-color: ${({ $isActive }) => ($isActive ? "#3578E5" : "#ffffff")};
  color: ${({ $isActive }) => ($isActive ? "#ffffff" : "#333")};
  font-weight: 600;
  font-family: jua;
  font-size: 1.5rem; 
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${({ $isActive }) => ($isActive ? "#2c68d6" : "#e7e7e7")};
  }

  &:first-child {
    border-radius: 8px 0 0 8px; 
  }

  &:last-child {
    border-radius: 0 8px 8px 0; 
  }

  &:focus {
    outline: none;
  }
`;
