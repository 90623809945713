import React from 'react';
import {PitcherDetailRes, PlayerDetailRes} from '../../../type/res/PlayerDetailRes';
import useRankBarYear from "../../../hooks/detail/useRankBarYear";
import PlayerDetailHeaderPair from "../info/PlayerDetailHeaderPair";
import PitcherCompareStat from "./PitcherCompareStat";
import {PairContainer, PlayerDetailSavantBarContainer, StatsContainer} from "./HitterCompareStatBar";

interface PitcherCompareStatBarProps {
  leftPlayerDetail?: PlayerDetailRes;
  rightPlayerDetail?: PlayerDetailRes;
}

const PitcherCompareStatBar = ({ leftPlayerDetail, rightPlayerDetail }: PitcherCompareStatBarProps) => {
  const leftPitchers = leftPlayerDetail?.pitcherStats || [];
  const rightPitchers = rightPlayerDetail?.pitcherStats || [];
  const leftPosition = leftPlayerDetail?.position || "";
  const rightPosition = rightPlayerDetail?.position || "";

  const {
    selectedYear: leftSelectedYear,
    handleYearChange: handleLeftYearChange,
    years: leftYears,
  } = useRankBarYear(leftPosition, [], leftPitchers);

  const {
    selectedYear: rightSelectedYear,
    handleYearChange: handleRightYearChange,
    years: rightYears,
  } = useRankBarYear(rightPosition, [], rightPitchers);

  const leftFilteredData = leftPitchers.filter((player: PitcherDetailRes) => player.year === leftSelectedYear);
  const rightFilteredData = rightPitchers.filter((player: PitcherDetailRes) => player.year === rightSelectedYear);

  const maxLength = Math.max(leftFilteredData.length, rightFilteredData.length);

  return (
      <PlayerDetailSavantBarContainer>
        <PairContainer>
          <PlayerDetailHeaderPair
              playerDetail={leftPlayerDetail}
              selectedYear={leftSelectedYear}
              handleYearChange={handleLeftYearChange}
              years={leftYears}
          />
          <PlayerDetailHeaderPair
              playerDetail={rightPlayerDetail}
              selectedYear={rightSelectedYear}
              handleYearChange={handleRightYearChange}
              years={rightYears}
          />
        </PairContainer>
        <StatsContainer>
          {Array.from({ length: maxLength }).map((_, index) => {
            const leftPlayer = leftFilteredData[index] || null;
            const rightPlayer = rightFilteredData[index] || null;

            return (
                <PitcherCompareStat
                    key={index}
                    leftPlayer={leftPlayer}
                    rightPlayer={rightPlayer}
                />
            );
          })}
        </StatsContainer>
      </PlayerDetailSavantBarContainer>
  );
};

export default PitcherCompareStatBar;
