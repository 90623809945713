import React, {ReactNode} from "react";
import styled from "styled-components";

interface EditableItemProps {
  children: ReactNode;
  isEditing: boolean;
  onSave: () => void;
  onCancel: () => void;
  onDelete: () => void;
  toggleEdit: () => void;
}

const EditableItem = ({ children, isEditing, onSave, onCancel, onDelete, toggleEdit }: EditableItemProps) => {
  return (
      <ItemContainer>
        {children}
        {isEditing ? (
            <ButtonGroup>
              <SaveButton type="button" onClick={onSave}>
                저장
              </SaveButton>
              <CancelButton type="button" onClick={onCancel}>
                취소
              </CancelButton>
            </ButtonGroup>
        ) : (
            <ButtonGroup>
              <EditButton type="button" onClick={toggleEdit}>
                수정
              </EditButton>
              <DeleteButton type="button" onClick={onDelete}>
                삭제
              </DeleteButton>
            </ButtonGroup>
        )}
      </ItemContainer>
  );
};

export default EditableItem;

const ItemContainer = styled.div`
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 10px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
`;

const SaveButton = styled(Button)`
  background-color: #28a745;

  &:hover {
    background-color: #218838;
  }
`;

const CancelButton = styled(Button)`
  background-color: #dc3545;

  &:hover {
    background-color: #c82333;
  }
`;

const EditButton = styled(Button)`
  background-color: #007bff;

  &:hover {
    background-color: #0056b3;
  }
`;

const DeleteButton = styled(Button)`
  background-color: #dc3545;

  &:hover {
    background-color: #c82333;
  }
`;
