import React, {useEffect, useState} from 'react';
import {FilterSelect} from '../common/Filter';

interface FilterBoxProps {
  id: string;
  value: number | string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: { value: number | string, label: string }[];
  prefix?: string;
}

function getInitialValue(prefix: string | undefined, options: { value: number | string; label: string }[], value: number | string) {
  return prefix ? `${prefix} (${options.find(option => option.value === value)?.label || value})` : `${options.find(option => option.value === value)?.label || value}`;
}

const FilterBox = ({id, value, onChange, options, prefix}: FilterBoxProps) => {
  const [displayValue, setDisplayValue] = useState<string>(getInitialValue(prefix, options, value));

  useEffect(() => {
    setDisplayValue(getInitialValue(prefix, options, value));
  }, [value, prefix, options]);

  return (
      <div style={{position: 'relative'}}>
        <FilterSelect id={id} value={value} onChange={onChange}>
          {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
          ))}
        </FilterSelect>
        <div style={{
          position: 'absolute',
          fontFamily: 'jua',
          top: '50%',
          left: '10px',
          transform: 'translateY(-50%)',
          pointerEvents: 'none',
        }}>
          {displayValue}
        </div>
      </div>
  );
};

export default FilterBox;
