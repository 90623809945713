import {CustomButton} from "./Button";


interface ActionButtonProps {
  title: string,
  backgroundColor?: string
  onClick: () => void;
}

const ConfirmButton = ({title, backgroundColor, onClick}: ActionButtonProps) => {
  return <CustomButton $backgroundColor={backgroundColor} onClick={onClick}>{title}</CustomButton>
}

export default ConfirmButton
