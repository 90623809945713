import React from 'react';
import styled from 'styled-components';

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 50px;
  color: #333;
`;

const NotFoundTitle = styled.h1`
  font-size: 48px;
  margin-bottom: 16px;
`;

const HomeButton = styled.a`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    background-color: #0056b3;
  }
`;

const NotFoundPage = () => {
  return (
      <NotFoundContainer>
        <NotFoundTitle>존재하지 않는 페이지입니다</NotFoundTitle>
        <HomeButton href="/">홈으로</HomeButton>
      </NotFoundContainer>
  );
};

export default NotFoundPage;
