import React, {ChangeEvent} from 'react';
import styled from 'styled-components';

interface EditableTitleComponentProps {
  title: string;
  setTitle: (title: string) => void;
}

const EditableTitleComponent = ({ title, setTitle } : EditableTitleComponentProps) => {

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  return (
      <Container>
        <TitleInput
            type="text"
            value={title}
            onChange={handleTitleChange}
            placeholder="Enter title here"
        />
      </Container>
  );
};

const Container = styled.div`
  margin-bottom: 20px;
`;

const TitleInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 1.5em;
  box-sizing: border-box;
`;

export default EditableTitleComponent;
