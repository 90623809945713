import {HitterStatRes} from "../../type/res/HitterStatRes";

interface StatTableColumn<HitterStatRes> {
  field: keyof HitterStatRes | 'index';
  header: string;
  sortable?: boolean;
}

const formatValue = (value: number | null, decimals: number) => {
  return value !== null ? value.toFixed(decimals) : '';
};

export const HitterStatRender = ({ stat, column }: { stat: HitterStatRes; column: StatTableColumn<HitterStatRes> }) => {
  const value = (stat as any)[column.field];
  switch (column.field) {
    case 'avg':
      return formatValue(value, 3);
    case 'obp':
      return formatValue(value, 3);
    case 'slg':
      return formatValue(value, 3);
    case 'ops':
      return formatValue(value, 3);
    case 'woba':
      return formatValue(value, 3);
    case 'expectedWobaCon':
      return formatValue(value, 3);
    case 'expectedWoba':
      return formatValue(value, 3);
    case 'expectedBattingAverage':
      return formatValue(value, 3);
    case 'exitVelocity':
      return formatValue(value, 1);
    case 'sweetSpotPercent':
      return formatValue(value, 1);
    case 'hardHitPercent':
      return formatValue(value, 1);
    case 'barrelPercent':
      return formatValue(value, 1);
    case 'barrelByPaPercent':
      return formatValue(value, 1);
    case 'solidLaunchAngle':
      return formatValue(value, 1);
    case 'whiff':
      return formatValue(value, 1);
    case 'chasePercent':
      return formatValue(value, 1);
    case 'swingDecision':
      return formatValue(value, 0)
    case 'callStrikePercent':
      return formatValue(value, 1);
    case 'icrPercent':
      return formatValue(value, 1);
    case 'ipaPercent':
      return formatValue(value, 1);
    default:
      return value;
  }
};
