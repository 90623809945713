import {useMutation} from "react-query";
import {uploadHitterExcelFile, uploadPitcherExcelFile} from "../../apis/ExcelUploadApi";
import {AxiosError} from "axios";
import {useState} from "react";
import {ErrorResponse} from "../../../common/Error";

const useExcelUpload = (
    uploadFunction: (formData: FormData) => Promise<any>,
    successMessage: string
) => {
  const [year, setYear] = useState<number | null>(null);
  const [file, setFile] = useState<File | null>(null);

  const mutation = useMutation({
    mutationFn: (formData: FormData) => uploadFunction(formData),
    onSuccess: () => {
      alert(successMessage);
      setYear(null);
      setFile(null);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
    },
  });

  const handleUpload = () => {
    if (year && file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('year', year.toString());
      mutation.mutate(formData);
    } else {
      alert("연도와 파일을 모두 선택해주세요.");
    }
  };

  return {
    year,
    setYear,
    file,
    setFile,
    handleUpload,
    isUploading: mutation.isLoading,
    error: mutation.error
  };
};

export const useHitterExcelUpload = () => {
  return useExcelUpload(uploadHitterExcelFile, '업로드에 성공했습니다.');
};

export const usePitcherExcelUpload = () => {
  return useExcelUpload(uploadPitcherExcelFile, '업로드에 성공했습니다.');
};
