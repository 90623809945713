import React from "react";
import {POSITIONS, PositionType} from "../constant/PositionConstants";
import PitcherComparePage from "./PitcherComparePage";
import useCompareContext from "../hooks/compare/useCompareContext";
import CompareButtonGroup from "../components/compare/poistion/ComparePositionButtonGroup";
import HitterComparePage from "./HitterComparePage";

const PlayerComparePage = () => {
  const {playerId, setPlayerId, position, setPosition} = useCompareContext();

  const onPositionChange = (newPosition: PositionType) => {
    setPosition(newPosition);
    setPlayerId(undefined);
  };

  return (
      <div>
        <CompareButtonGroup activePosition={position} onPositionChange={onPositionChange}/>
        {position === POSITIONS.PITCHER ? (
            <PitcherComparePage playerId={playerId} position={position}/>
        ) : (
            <HitterComparePage playerId={playerId} position={position}/>
        )}
      </div>
  );
};

export default PlayerComparePage;
