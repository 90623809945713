import React from 'react';
import {HeaderRow, Table, Td} from "../common/StatTable";
import NameCell from "./NameCell";

interface StatTableColumn<T> {
  field: keyof T | 'index';
  header: string;
  sortable?: boolean;
}

interface StatSort<T> {
  sortField: keyof T;
  sortOrder: 'asc' | 'desc';
  handleSortChange: (field: keyof T) => void;
}

interface StatTableComponentProps<T> {
  columns: StatTableColumn<T>[];
  stats: T[];
  currentPage: number;
  pageSize: number;
  statSort: StatSort<T>;
  SortComponent: React.ComponentType<{
    column: StatTableColumn<T>;
    sortField: keyof T;
    sortOrder: 'asc' | 'desc';
    handleSort: (field: keyof T) => void;
  }>;
  renderCell?: (stat: T, column: StatTableColumn<T>) => React.ReactNode;
}

const StatTable = <T,>({ columns, stats, currentPage, pageSize, statSort, SortComponent, renderCell }: StatTableComponentProps<T>) => {
  const handleSort = (field: keyof T) => {
    statSort.handleSortChange(field);
  };

  return (
    <Table>
      <thead>
      <HeaderRow>
        {columns.map((column, index) => (
          <SortComponent
            key={index}
            column={column}
            sortField={statSort.sortField}
            sortOrder={statSort.sortOrder}
            handleSort={handleSort}
          />
        ))}
      </HeaderRow>
      </thead>
      <tbody>
      {stats.length === 0 ? (
        <tr>
          <Td colSpan={columns.length} $isSorted={false} style={{ textAlign: 'center' }}>
            해당하는 데이터가 존재하지 않습니다
          </Td>
        </tr>
      ) : (
        stats.map((stat, rowIndex) => (
          <tr key={(stat as any).playerId}>
            {columns.map((column, colIndex) => {
              if (column.field === 'playerId') return null;
              return (
                <Td key={colIndex} $isSorted={column.field === statSort.sortField}>
                  {column.field === 'index'
                    ? (currentPage - 1) * pageSize + rowIndex + 1
                    : column.field === 'name'
                      ? <NameCell playerId={(stat as any).playerId} name={(stat as any)[column.field]} />
                    : renderCell
                      ? renderCell(stat, column)
                      : (stat as any)[column.field]}
                </Td>
              );
            })}
          </tr>
        ))
      )}
      </tbody>
    </Table>
  );
};

export default StatTable;
