import React from 'react';
import {PitcherStatRes} from '../../type/pitcher/PitcherStatRes';
import CommonSort from './CommonSort';

type PitcherStatColumn = {
  header: string;
  field: keyof PitcherStatRes | 'index';
};

interface PitcherSortProps {
  column: PitcherStatColumn;
  sortField: keyof PitcherStatRes;
  sortOrder: 'asc' | 'desc';
  handleSort: (field: keyof PitcherStatRes) => void;
}

const SORTABLE_COLUMNS: (keyof PitcherStatRes)[] = [
  'innings',
  'era',
  'fip',
  'ra9',
  'strikeOut',
  'baseOnBalls',
  'yfxEra',
  'yfSiera',
  'whiff',
  'chasePercent',
  'exitVelocity',
  'barrelByPaPercent',
  'barrelPercent',
  'hardHitPercent',
  'ipaPercent',
  'icrPercent',
  'callStrikePercent',
  'icrPercent',
  'expectedWoba',
  'expectedWobaCon'
];

const PitcherSort = ({column, sortField, sortOrder, handleSort}: PitcherSortProps) => (
    <CommonSort
        column={column}
        sortField={sortField}
        sortOrder={sortOrder}
        handleSort={(field) => SORTABLE_COLUMNS.includes(field) && handleSort(field)}
    />
);

export default PitcherSort;
