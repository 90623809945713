import React from 'react';
import styled from 'styled-components';
import {PlayerDetailRes} from "../../../type/res/PlayerDetailRes";
import HitterCompareStatBar from "./HitterCompareStatBar";

interface HitterCompareTotalStatBarProps {
  leftPlayerDetail?: PlayerDetailRes;
  rightPlayerDetail?: PlayerDetailRes;
}

const HitterCompareTotalStatBar = ({leftPlayerDetail, rightPlayerDetail}: HitterCompareTotalStatBarProps) => {
  return (
      <CompareStatBarContainer>
        <HitterDetailSavantBarContainer>
          {(leftPlayerDetail || rightPlayerDetail) ? (
              <HitterCompareStatBar leftPlayerDetail={leftPlayerDetail} rightPlayerDetail={rightPlayerDetail}/>
          ) : (
              <div/>
          )}
        </HitterDetailSavantBarContainer>
      </CompareStatBarContainer>
  );
};

export const CompareStatBarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const HitterDetailSavantBarContainer = styled.div`
  width: 100%;
  justify-content: center;
  flex-direction: column;

  text-align: center;
`;

export default HitterCompareTotalStatBar;
