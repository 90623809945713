import React, {useState} from "react";
import styled from "styled-components";
import {useRenewHitterCache, useRenewPitcherCache} from "../../hooks/query/useRenewCache";

const CacheRenewComponent: React.FC = () => {
  const [year, setYear] = useState<number>(2024);

  const { mutate: renewHitter } = useRenewHitterCache({
    onSuccess: () => {
      alert("타자 캐시가 성공적으로 갱신되었습니다!");
    },
    onError: (error) => {
      alert(`타자 캐시 갱신 중 오류가 발생했습니다: ${error.message}`);
    },
  });

  const { mutate: renewPitcher } = useRenewPitcherCache({
    onSuccess: () => {
      alert("투수 캐시가 성공적으로 갱신되었습니다!");
    },
    onError: (error) => {
      alert(`투수 캐시 갱신 중 오류가 발생했습니다: ${error.message}`);
    },
  });

  const handleHitterCacheRenewal = () => {
    renewHitter(year);
  };

  const handlePitcherCacheRenewal = () => {
    renewPitcher(year);
  };

  return (
      <Container>
        <Title>캐시 갱신</Title>
        <Label>연도</Label>
        <YearSelect value={year} onChange={(e) => setYear(Number(e.target.value))}>
          {Array.from({ length: 9 }, (_, i) => 2016 + i).map((yearOption) => (
              <option key={yearOption} value={yearOption}>
                {yearOption}
              </option>
          ))}
        </YearSelect>
        <ButtonContainer>
          <ActionButton onClick={handleHitterCacheRenewal}>타자 캐시 갱신</ActionButton>
          <ActionButton onClick={handlePitcherCacheRenewal}>투수 캐시 갱신</ActionButton>
        </ButtonContainer>
      </Container>
  );
};

export default CacheRenewComponent;

const Container = styled.div`
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  max-width: 400px;
  margin: 0 auto;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
  color: #555;
`;

const YearSelect = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionButton = styled.button`
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  flex: 1;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;
