import React, {useEffect} from 'react';
import './App.css';
import StatPageComponent from "./components/StatPageComponent";
import HeaderComponent from "./components/common/header/HeaderComponent";
import {Outlet, useLocation} from "react-router-dom";
import {PATH} from "./constant/Path";
import ReactGA from 'react-ga4';
import LoadingOverlay from "./context/loading/LoadingOverlay";
import {LoadingProvider} from "./context/loading/LoadingContext";
import {Helmet} from 'react-helmet';

const App: React.FC = () => {
  const location = useLocation();
  const isProd = process.env.REACT_APP_ENV === 'prod';

  useEffect(() => {
    if (process.env.REACT_APP_GA_CODE != null) {
      ReactGA.initialize(process.env.REACT_APP_GA_CODE);
    }
  }, []);

  return (
      <LoadingProvider>
        <div className="App">
          <Helmet>
            {isProd && <title>KBO INSIGHT</title>}
            {process.env.REACT_APP_ENV === 'dev' && (
                <meta name="robots" content="noindex, nofollow" />
            )}
          </Helmet>
          <LoadingOverlay />
          <HeaderComponent />
          <Outlet />
          {location.pathname === PATH.HOME && <StatPageComponent />}
        </div>
      </LoadingProvider>
  );
};

export default App;
