import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useLogin} from '../hooks/useLogin';
import {useNavigate} from 'react-router-dom';
import {PATH} from "../../../constant/Path";
import ErrorMessage from "../../components/ErrorMessage";
import {getSession} from "../function/Login";

const LoginPage = () => {
  const { login, loading, error } = useLogin();
  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const sessionKey = getSession()
    if (sessionKey) {
      navigate(PATH.ADMIN);
    }
  }, [navigate]);

  const handleLogin = () => {
    login({ loginId, password }, {
      onSuccess: () => {
        navigate(PATH.ADMIN);
      },
    });
  };

  return (
      <LoginContainer>
        <LoginBox>
          <Title>로그인</Title>

          <Label>아이디</Label>
          <Input
              type="text"
              value={loginId}
              onChange={(e) => setLoginId(e.target.value)}
              placeholder="아이디를 입력하세요"
          />

          <Label>비밀번호</Label>
          <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="비밀번호를 입력하세요"
          />

          <LoginButton onClick={handleLogin} disabled={loading || !loginId || !password}>
            {loading ? '로그인 중...' : '로그인'}
          </LoginButton>
          <ErrorMessage error={error}/>
        </LoginBox>
      </LoginContainer>
  );
};

export default LoginPage;

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
`;

const LoginBox = styled.div`
  padding: 40px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  text-align: center;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`;

const LoginButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;
