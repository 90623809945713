import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAddPost} from '../../hooks/query/usePost';
import {PATH} from "../../../constant/Path";
import WirteEditor from "./WirteEditor";
import PostHomeButton from "./PostHomeButton";
import ConfirmButton from "../../styles/ConfirmButton";
import EditableTitleComponent from "./EditablePostTitle";

const CreatePost = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const navigate = useNavigate();
  const {addPost} = useAddPost();

  const handleSubmit = async () => {
    await addPost({title, content});
    navigate(PATH.POST);
  };

  return (
      <div>
        <EditableTitleComponent title={title} setTitle={setTitle} />
        <WirteEditor content={content} onChange={setContent}/>
        <ConfirmButton title="완료" backgroundColor={"#28a745"} onClick={handleSubmit}/>
        <PostHomeButton title="취소"/>
      </div>
  );
};

export default CreatePost;
