import {useNavigate} from "react-router-dom";
import {PATH} from "../../../constant/Path";
import {CustomButton} from "../../styles/Button";

interface PostHomeButtonProps {
  title: string
  backgroundColor?: string
}

const PostHomeButton = ({title, backgroundColor}: PostHomeButtonProps) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(PATH.POST)
  }
  return <CustomButton onClick={handleClick} $backgroundColor={backgroundColor}>{title}</CustomButton>
}

export default PostHomeButton
