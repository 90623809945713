import {PitcherStatRes} from "../../type/pitcher/PitcherStatRes";

interface StatTableColumn<PitcherStatRes> {
  field: keyof PitcherStatRes | 'index';
  header: string;
  sortable?: boolean;
}

const formatValue = (value: number | null, decimals: number) => {
  return value !== null ? value.toFixed(decimals) : '';
};

export const PitcherStatRender = ({ stat, column }: { stat: PitcherStatRes; column: StatTableColumn<PitcherStatRes> }) => {
  const value = (stat as any)[column.field];
  switch (column.field) {
    case 'yfxEra':
      return formatValue(value, 2);
    case 'yfSiera':
      return formatValue(value, 2);
    case 'whiff':
      return formatValue(value, 1);
    case 'chasePercent':
      return formatValue(value, 1);
    case 'exitVelocity':
      return formatValue(value, 1);
    case 'barrelPercent':
      return formatValue(value, 1);
    case 'barrelByPaPercent':
      return formatValue(value, 1);
    case 'ipaPercent':
      return formatValue(value, 1);
    case 'icrPercent':
      return formatValue(value, 1);
    case 'expectedWobaCon':
      return formatValue(value, 3);
    case 'expectedWoba':
      return formatValue(value, 3);
    case 'callStrikePercent':
      return formatValue(value, 1);
    case 'innings':
      return formatValue(value, 1);
    case 'era':
      return formatValue(value, 2);
    case 'fip':
      return formatValue(value, 2);
    case 'ra9':
      return formatValue(value, 2);
    default:
      return value;
  }
};
