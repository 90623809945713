import React from 'react';
import styled from 'styled-components';
import 'react-quill/dist/quill.snow.css';

const ContentContainer = styled.div`
  .ql-align-center {
    text-align: center;
  }

  .ql-align-center img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%; 
    height: auto
  }
`;

interface PostContentProps {
  content: string;
}

const PostContent = ({content}: PostContentProps) => {
  return <ContentContainer dangerouslySetInnerHTML={{__html: content}}/>;
};

export default PostContent;
