import React, {ChangeEvent, useEffect} from "react";
import styled from "styled-components";
import {AxiosError} from "axios";
import {ErrorResponse} from "../../../common/Error";
import ErrorMessage from "../ErrorMessage";

interface ExcelUploadSectionProps {
  title: string;
  year: number | null;
  setYear: React.Dispatch<React.SetStateAction<number | null>>;
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  handleUpload: () => void;
  isUploading: boolean;
  error: AxiosError<ErrorResponse> | null;
}

const YEARS = Array.from({ length: 9 }, (_, i) => 2016 + i);
const DEFAULT_YEAR = 2024;

const ExcelUploadSection = ({title, year = DEFAULT_YEAR, setYear, file, setFile, handleUpload, isUploading, error,}: ExcelUploadSectionProps) => {
  useEffect(() => {
    if (year === null) {
      setYear(DEFAULT_YEAR);
    }
  }, [year, setYear]);

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    setFile(selectedFile || null);
  };

  const handleYearChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setYear(Number(event.target.value));
  };

  const confirmAndUpload = () => {
    if (file && year) {
      const confirmation = window.confirm(
          `다음 정보를 업로드하시겠습니까?\n\n` +
          `속성: ${title}\n` +
          `연도: ${year}\n` +
          `파일 이름: ${file.name}`
      );
      if (confirmation) {
        handleUpload();
      }
    } else {
      alert("연도와 파일을 모두 선택해주세요.");
    }
  };

  return (
      <div>
        <SectionTitle>{title} 업로드</SectionTitle>
        <Label>연도</Label>
        <YearSelect value={year || DEFAULT_YEAR} onChange={handleYearChange}>
          <option value="">선택</option>
          {YEARS.map((year) => (
              <option key={year} value={year.toString()}>
                {year}
              </option>
          ))}
        </YearSelect>

        <Label>파일</Label>
        <FileInput type="file" onChange={handleFileUpload} />

        <UploadButton
            onClick={confirmAndUpload}
            disabled={isUploading || !file || !year}
        >
          {isUploading ? "업로드 중..." : "업로드"}
        </UploadButton>
        <ErrorMessage error={error}/>
      </div>
  );
};

export default ExcelUploadSection;

const SectionTitle = styled.h3`
  margin-bottom: 10px;
  margin-top: 30px;
`;

const Label = styled.div`
  margin: 10px 0 5px;
  font-weight: bold;
`;

const YearSelect = styled.select`
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
`;

const FileInput = styled.input`
  margin-bottom: 20px;
`;

const UploadButton = styled.button`
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;
