import {PostResponse} from "../../apis/PostApi";
import styled from "styled-components";
import {PATH} from "../../../constant/Path";
import {InternalNavLink} from "../../../components/common/header/HeaderStyle";


interface PostProps {
  posts: PostResponse[] | undefined
}

const PostList = ({posts}: PostProps) => {
  return <StyledPostList>
    {posts && posts.map(post => (
        <PostItem key={post.id}>
          <PostLink to={PATH.POST_DETAIL.replace(':postId', post.id.toString())}>
            {post.title}
          </PostLink>
          <PostDate>{new Date(post.createdDate)
          .toISOString()
          .replace("T", " ")
          .substring(0, 16)
          }
          </PostDate>
        </PostItem>
    ))}
  </StyledPostList>
}

export default PostList


const StyledPostList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const PostItem = styled.li`
  margin-bottom: 1rem; /* 16px */
  padding: 1rem; /* 16px */
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem; /* 8px */
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1); /* 2px, 4px */
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-0.125rem); /* -2px */
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15); /* 4px, 8px */
  }
`;

const PostLink = styled(InternalNavLink)`
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 1.1rem; /* 약 17.6px */

  &:hover {
    color: #007bff;
  }
`;

const PostDate = styled.p`
  color: #777;
  font-size: 0.9rem;
  margin-top: 0.5rem;
`;
