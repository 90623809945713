import React from 'react';
import styled from 'styled-components';
import {getFullTeamName, getTeamColor} from "../../functions/profile";
import {PlayerDetailRes} from "../../type/res/PlayerDetailRes";

const ProfileContainer = styled.div<{ $teamColor: string }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: ${(props) => props.$teamColor};
  color: white;
  width: 50%;
  padding: 20px;
  border-radius: 5px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 992px) {
    width: 95%;
  }
`;

const PlayerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PlayerName = styled.h1`
  font-size: 44px;
  font-weight: bold;
  margin: 0 0 5px;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 36px;
  }

  @media (max-width: 480px) {
    font-size: 28px;
  }
`;

const PlayerTeam = styled.h2`
  font-size: 24px;
  margin: 20px 0 5px;
  font-family: jua;
  font-weight: normal;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const PlayerTexts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px;
  margin-bottom: 1px;

  @media (max-width: 768px) {
    align-items: flex-start;
    margin-top: auto;
    margin-bottom: 1px;
  }
`;

const PlayerText = styled.div`
  font-size: 18px;
  font-family: jua;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 11px;
  }
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 768px) {
    align-items: flex-end;
  }
`;

const Position = styled.div`
  font-size: 32px;
  font-family: jua;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 28px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

interface PlayerProfileProps {
  player: PlayerDetailRes;
}

const PlayerProfileContainer = ({player}: PlayerProfileProps) => {
  const birthDate = new Date(player.birthDate);
  const today = new Date();
  const age = today.getFullYear() - birthDate.getFullYear();

  const fullTeamName = getFullTeamName(player.team);
  const teamColor = getTeamColor(player.team);

  return (
      <ProfileContainer $teamColor={teamColor}>
        <Row>
          <PlayerInfo>
            <PlayerName>{player.name}</PlayerName>
            <PlayerTeam>{fullTeamName}</PlayerTeam>
          </PlayerInfo>
          <RightSection>
            <Position>{player.position}</Position>
            <PlayerTexts>
              <PlayerText>{age}세 {player.handInfo}</PlayerText>
              <PlayerText>{player.height}cm {player.weight}kg</PlayerText>
              <PlayerText>{birthDate.toLocaleDateString()}</PlayerText>
            </PlayerTexts>
          </RightSection>
        </Row>
      </ProfileContainer>
  );
};

export default PlayerProfileContainer;
