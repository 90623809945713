import React, {useState} from "react";
import styled from "styled-components";
import {useGlossaries} from "../../../hooks/common/useGlossary";

const Glossary = () => {
  const { glossaries } = useGlossaries();
  const [activeIndices, setActiveIndices] = useState<number[]>([]);

  const toggleActiveIndex = (index: number) => {
    setActiveIndices((prevIndices) =>
        prevIndices.includes(index)
            ? prevIndices.filter((i) => i !== index)
            : [...prevIndices, index]
    );
  };

  return (
      <Container>
        <Title>FAQ</Title>
        <GlossaryList>
          {glossaries?.map((glossary, index) => (
              <GlossaryItem
                  key={glossary.id}
                  onClick={() => toggleActiveIndex(index)}
              >
                <GlossaryHeader>
                  <GlossaryTitle>{glossary.name}</GlossaryTitle>
                  <ToggleIcon isActive={activeIndices.includes(index)}>▼</ToggleIcon>
                </GlossaryHeader>
                {activeIndices.includes(index) && (
                    <GlossaryDescription>{glossary.description}</GlossaryDescription>
                )}
              </GlossaryItem>
          ))}
        </GlossaryList>
      </Container>
  );
};

export default Glossary;

const Container = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 28px;
  font-family: jua;
  text-align: left;
  margin-bottom: 20px;
  color: #333;
`;

const GlossaryList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;

  @media (max-width: 720px) {
    grid-template-columns: 1fr;
  }
`;

const GlossaryItem = styled.div`
  background-color: #f8f8f8;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ececec;
  }
`;

const GlossaryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GlossaryTitle = styled.h2`
  font-size: 20px;
  font-family: jua;
  font-weight: normal;
  color: #3B5998;
  margin: 0;
`;

const GlossaryDescription = styled.p`
  font-size: 16px;
  font-family: jua;
  color: #21231f;
  text-align: left;
  margin-top: 10px;
  white-space: pre-wrap;
`;

const ToggleIcon = styled.span<{ isActive: boolean }>`
  transition: transform 0.3s ease;
  transform: rotate(${(props) => (props.isActive ? "180deg" : "0deg")});
  font-size: 14px;
  color: #666;
`;
