import React from 'react';

interface LoadingOrErrorProps {
  error: string | null;
  children: React.ReactNode;
}

const LoadingOrError: React.FC<LoadingOrErrorProps> = ({error, children }) => {
  if (error) {
    return <div>{error}</div>;
  }

  return <>{children}</>;
};

export default LoadingOrError;
