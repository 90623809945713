import React from 'react';
import styled from 'styled-components';
import RankBar from './RankBar';
import TooltipWithState from "../../common/CustomTooltip";

interface StatRowItemProps {
  label: string;
  rank: number | null | undefined;
  value: string | number | null | undefined;
  description?: string;
}

const StatRowItem = ({ label, rank, value, description }: StatRowItemProps) => {
  return (
      <StatRow>
        <TooltipWithState description={description || ''}>
          <StatLabel>
            {label}
          </StatLabel>
        </TooltipWithState>
        <RankBar rank={rank} />
        <StatValue>{value}</StatValue>
      </StatRow>
  );
};


export default StatRowItem;

export const StatRow = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
  border-bottom: 1px solid #ccc;
  font-family: jua;
  position: relative;
`;

export const StatLabel = styled.div`
  width: 100px;
  text-align: left;
  position: relative;
  cursor: pointer;
`;

export const StatValue = styled.div`
  margin-left: 10px;
  font-weight: bold;
  min-width: 50px;
  font-size: 14px;
  text-align: right;
`;
