import {useNavigate} from 'react-router-dom';
import {useCallback} from 'react';
import {POSITIONS, PositionType} from "../../constant/PositionConstants";

const useNavigateToCompare = () => {
  const navigate = useNavigate();

  const handleCompareClick = useCallback((playerId: number, position: string) => {
    if (Object.values(POSITIONS).includes(position as PositionType)) {
      navigate(`/compare-players?playerId=${playerId}&position=${position}`);
    } else {
      console.error('Invalid position type:', position);
    }
  }, [navigate]);

  return { handleCompareClick };
};

export default useNavigateToCompare;
