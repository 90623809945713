import React from 'react';
import styled from 'styled-components';
import CompareSearchBar from './CompareSearchBar';
import ComparePlayerSearchList from './ComparePlayerSearchList';
import {PositionType} from '../../../constant/PositionConstants';
import usePositionSearch from '../../../hooks/search/usePositionSearch';

interface CompareSearchProps {
  position: PositionType;
  onSelectPlayer: (playerId: number) => void;
}

const CompareSearch = ({ position, onSelectPlayer }: CompareSearchProps) => {

  const { positionPlayers, keyword, setKeyword,isFocused, setIsFocused}
      = usePositionSearch({ position });

  return (
      <Container>
          <CompareSearchBar
              keyword={keyword}
              setKeyword={setKeyword}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setTimeout(() => setIsFocused(false), 200)}
          />
          <ComparePlayerSearchList isFocused={isFocused} players={positionPlayers} onSelectPlayer={onSelectPlayer}/>
      </Container>
  );
};


export default CompareSearch;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;          
  margin: 1rem;
  position: relative;
`;
