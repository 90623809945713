import {client} from "./ApiClient";

export interface AnnouncementRequest {
  content: string;
}

export interface AnnouncementAdminResponse {
  id: number;
  content: string;
  announcementDateTime: Date;
}

export const getAnnouncements = async (): Promise<AnnouncementAdminResponse[]> => {
  let response = await client.get<AnnouncementAdminResponse[]>('/admin/announcements');
  return response.data;
};

export const addAnnouncement = async (request: AnnouncementRequest): Promise<void> => {
  await client.post('/admin/announcements', request);
};

export const updateAnnouncement = async (announcementId: number, request: AnnouncementRequest): Promise<void> => {
  await client.put(`/admin/announcements/${announcementId}`, request);
};

export const deleteAnnouncement = async (announcementId: number): Promise<void> => {
  await client.delete(`/admin/announcements/${announcementId}`);
};
