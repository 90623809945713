import styled from 'styled-components';
import {useFetchPosts} from "../../hooks/query/usePost";
import {useNavigate} from "react-router-dom";
import {PATH} from "../../../constant/Path";
import PostList from "./StyledPostList";
import ConfirmButton from "../../styles/ConfirmButton";


const PostListPage = () => {
  const { posts, error, isLoading } = useFetchPosts();
  const navigate = useNavigate()

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading posts: {error.message}</div>;
  }

  const handleCreatePost = () => {
    navigate(PATH.POST_NEW);
  };

  return (
      <div>
        <StyledHeader>글 목록</StyledHeader>
        <PostList posts={posts}/>
        <ConfirmButton title="글쓰기" backgroundColor={"#007bff"} onClick={handleCreatePost}/>
      </div>
  );
};

export default PostListPage;

const StyledHeader = styled.h1`
  font-size: 2.5rem;
  font-family: jua;
  color: #333;
  text-align: center; 
  margin-bottom: 2.5rem;
  position: relative; 
  display: inline-block;
  padding-bottom: 0.5rem;

  &::after {
    content: '';
    display: block;
    width: 50%;
    height: 4px;
    background-color: #007bff;
    margin: 0.5rem auto 0; 
    border-radius: 2px; 
  }
`;
