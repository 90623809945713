import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

const useQueryString = (key: string, initialValue: string, clearOtherParams: boolean = false) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const valueFromQuery = queryParams.get(key);
  const initial = valueFromQuery !== null ? valueFromQuery : initialValue;

  const [value, setValue] = useState(initial);

  const handleValueChange = (newValue: string) => {
    setValue(newValue);
    let newQueryParams = new URLSearchParams(location.search);
    if (clearOtherParams) {
      newQueryParams = new URLSearchParams(); // Clear all other params
    }
    newQueryParams.set(key, newValue);
    navigate({ search: newQueryParams.toString() });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const valueFromQuery = queryParams.get(key);
    if (valueFromQuery !== null && valueFromQuery !== value) {
      setValue(valueFromQuery);
    }
  }, [location.search, key, value]);

  return [value, handleValueChange] as const;
};

export default useQueryString;
