import React, {useState} from "react";
import {Tooltip} from "@mui/material";

const TooltipWithState = ({ description, children }: { description: string; children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
      <Tooltip
          title={description}
          placement="top"
          open={open}
          onClose={handleTooltipClose}
          onOpen={handleTooltipOpen}
      >
        <div onClick={open ? handleTooltipClose : handleTooltipOpen}>
          {children}
        </div>
      </Tooltip>
  );
};

export default TooltipWithState
