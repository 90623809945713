import React, {useState} from 'react';
import styled from 'styled-components';

interface Option {
  value: number | string;
  label: number | string;
}

interface CustomDropdownProps {
  value: number | string;
  options: Option[];
  onChange: (value: number | string) => void;
}

export const DetailDropdown = ({value, options, onChange}: CustomDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (optionValue: number | string) => {
    onChange(optionValue);
    setIsOpen(false);
  };

  return (
      <DropdownContainer>
        <DropdownButton onClick={() => setIsOpen(!isOpen)}>
          {value} <ArrowIcon viewBox="0 0 20 20" fill="currentColor">
          <path d="M10 15l-7-7h14l-7 7z"/>
        </ArrowIcon>
        </DropdownButton>
        {isOpen && (
            <DropdownList>
              {options.map((option, index) => (
                  <DropdownListItem key={index} onClick={() => handleOptionClick(option.value)}>
                    {option.label}
                  </DropdownListItem>
              ))}
            </DropdownList>
        )}
      </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  font-family: jua;
`;

const DropdownButton = styled.button`
  font-size: 20px;
  margin-top: 0.3rem;
  font-weight: bold;
  margin-right: 5px;
  padding: 5px 10px;
  border: 1px solid black;
  background-color: transparent;
  color: black;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    background-color: #f0f0f0;
  }
`;


const ArrowIcon = styled.svg`
  width: 20px;
  height: 20px;
  margin-left: 5px;
`;

const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  color: #231f21;
  background-color: white;
  border: 1px solid #ccc;
  width: 100%;
  max-height: 200px;
  appearance: none;
  overflow-y: auto;
  z-index: 1;
  padding: 0;
  margin: 0;
  list-style: none;
`;

const DropdownListItem = styled.li`
  font-size: 14px;
  padding: 8px 10px;
  cursor: pointer;
  color: black;
  background-color: white;

  &:hover {
    background-color: #e0e0e0;
  }
`;

export default DetailDropdown;
