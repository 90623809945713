import React from 'react';
import {useNavigate} from 'react-router-dom';
import {PATH} from "../../constant/Path";

export const generatePath = (template: string, params: { [key: string]: string | number }) => {
  return template.replace(/:([a-zA-Z]+)/g, (_, key) => params[key].toString());
};

const NameCell: React.FC<{ playerId: number; name: string }> = ({ playerId, name }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const path = generatePath(PATH.PLAYER_DETAIL, { playerId });
    navigate(path);
  };

  return (
    <span onClick={handleClick} style={{cursor: 'pointer', fontWeight: 'bold', color: 'black'}}>
      {name}
    </span>
  );
};

export default NameCell;
