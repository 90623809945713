import {client} from "./ApiClient";
import {PitcherStatRes} from "../type/pitcher/PitcherStatRes";

export class PitcherStatApi {
  static async getStats(year: number): Promise<PitcherStatRes[]> {
    let response = await client.get<PitcherStatRes[]>(`/pitchers`, {
      params: {
        year: year
      }
    });
    return response.data
  }
}
