export const getBackgroundColor = (value?: number | null): string => {
  if (value === undefined || value === null) return "";

  const minColorValue = 190;
  const maxColorValue = 140;

  if (value >= 80) {
    const redComponent = maxColorValue + Math.round((minColorValue - maxColorValue) * (100 - value) / 10);
    return `rgb(255, ${redComponent}, ${redComponent})`; // 붉은 계열
  }

  if (value <= 20) {
    const blueComponent = maxColorValue + Math.round((minColorValue - maxColorValue) * value / 10);
    return `rgb(${blueComponent}, ${blueComponent}, 255)`; // 파란 계열
  }

  return "";
};
