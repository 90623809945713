import React from "react";
import styled from "styled-components";
import {useHitterExcelUpload, usePitcherExcelUpload} from "../../hooks/query/useExcelUpload";
import ExcelUploadSection from "./ExcelUploadSection";

const ExcelUpload = () => {
  const hitterUpload = useHitterExcelUpload();
  const pitcherUpload = usePitcherExcelUpload();

  return (
      <UploadSection>
        <Title>엑셀 업로드</Title>

        <ExcelUploadSection
            title="타자"
            year={hitterUpload.year}
            setYear={hitterUpload.setYear}
            file={hitterUpload.file}
            setFile={hitterUpload.setFile}
            handleUpload={hitterUpload.handleUpload}
            isUploading={hitterUpload.isUploading}
            error={hitterUpload.error}
        />
        <SectionDivider />
        <ExcelUploadSection
            title="투수"
            year={pitcherUpload.year}
            setYear={pitcherUpload.setYear}
            file={pitcherUpload.file}
            setFile={pitcherUpload.setFile}
            handleUpload={pitcherUpload.handleUpload}
            isUploading={pitcherUpload.isUploading}
            error={pitcherUpload.error}
        />
      </UploadSection>
  );
};

export default ExcelUpload;

const UploadSection = styled.div`
  padding: 20px;
  border-top: 1px solid #ddd;
  background-color: #f9f9f9;
`;

const Title = styled.h2`
  margin-bottom: 20px;
`;

const SectionDivider = styled.hr`
  margin: 40px 0;
  border: none;
  border-top: 1px solid #ddd;
`;
