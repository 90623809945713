import React, {useState} from 'react';
import SearchComponent from "../serach/SearchComponent";
import {PATH} from "../../../constant/Path";
import Logo from "./Logo";
import {ExternalNavLink, HamburgerButton, Header, InternalNavLink, Nav, TopRow} from "./HeaderStyle";
import AnnouncementList from "../announcement/AnnouncementList";

const HeaderComponent = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Header>
      <TopRow>
        <Logo/>
        <HamburgerButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
          {isMenuOpen ? '✕' : '☰'}
        </HamburgerButton>
      </TopRow>
      <AnnouncementList/>
      <SearchComponent/>
      <Nav $isOpen={isMenuOpen}>
        <InternalNavLink to={PATH.HOME}>연도별 기록</InternalNavLink>
        <InternalNavLink to={PATH.COMPARE_PLAYER}>선수 비교</InternalNavLink>
        <InternalNavLink to={PATH.FAQ}>도움말</InternalNavLink>
        <ExternalNavLink href="https://docs.google.com/forms/d/e/1FAIpQLSfRKUOg9c2TzTo5Ncgn-BdBvpwPcDm9cCOpQUM_peVCy3keZw/viewform">문의하기</ExternalNavLink>
      </Nav>
    </Header>
  )
    ;
};

export default HeaderComponent;
