import React from 'react';
import styled from 'styled-components';
import StatRowItem from './StatRowItem';
import {HitterDetailRes} from '../../../type/res/PlayerDetailRes';
import RankSampleLowMessage from "./RankSampleLowMessage";
import {
  BARREL_DESCRIPTION,
  CHASE_DESCRIPTION,
  CSW_DESCRIPTION,
  EV_DESCRIPTION,
  HARDHIT_DESCRIPTION,
  ICR_DESCRIPTION,
  IPA_DESCRIPTION,
  SD_LA_DESCRIPTION,
  SWSP_DESCRIPTION,
  WHIFF_DESCRIPTION,
  XBA_DESCRIPTION,
  XWOBA_CON_DESCRIPTION,
  XWOBA_DESCRIPTION,
  ZONE_JUDGEMENT_DESCRIPTION
} from "./StatDescriptionConst";

interface HitterStatBarProps {
  player: HitterDetailRes;
}

const HitterStatsBar = ({player}: HitterStatBarProps) => {
  return (
      <PlayerStatsContainer>
        <Section>
          <RankSampleLowMessage condition={player.rank == null} message="샘플이 부족합니다"/>
          <StatRowItem
              label="xwOBA"
              rank={player.rank?.expectedWoba}
              value={player.expectedWoba?.toFixed(3)}
              description={XWOBA_DESCRIPTION}
          />
          <StatRowItem
              label="xwOBAcon"
              rank={player.rank?.expectedWobaCon}
              value={player.expectedWobaCon?.toFixed(3)}
              description={XWOBA_CON_DESCRIPTION}
          />
          <StatRowItem
              label="xBA"
              rank={player.rank?.expectedBattingAverage}
              value={player.expectedBattingAverage?.toFixed(3)}
              description={XBA_DESCRIPTION}
          />
          <StatRowItem
              label="EV"
              rank={player.rank?.exitVelocity}
              value={player.exitVelocity}
              description={EV_DESCRIPTION}
          />
          <StatRowItem
              label="Barrel %"
              rank={player.rank?.barrelPercent}
              value={`${player.barrelPercent?.toFixed(1)}%`}
              description={BARREL_DESCRIPTION}
          />
          <StatRowItem
              label="HardHit %"
              rank={player.rank?.hardHitPercent}
              value={`${player.hardHitPercent?.toFixed(1)}%`}
              description={HARDHIT_DESCRIPTION}
          />
          <StatRowItem
              label="ICR %" rank={player.rank?.icrPercent}
              value={`${player.icrPercent?.toFixed(1)}%`}
              description={ICR_DESCRIPTION}
          />
          <StatRowItem
              label="IPA %"
              rank={player.rank?.ipaPercent}
              value={`${player.ipaPercent?.toFixed(1)}%`}
              description={IPA_DESCRIPTION}
          />
          <StatRowItem
              label="SwSp %"
              rank={player.rank?.sweetSpotPercent}
              value={`${player.sweetSpotPercent?.toFixed(1)}%`}
              description={SWSP_DESCRIPTION}
          />
          <StatRowItem
              label="sd(LA)"
              rank={player.rank?.solidLaunchAngle}
              value={player.solidLaunchAngle?.toFixed(1)}
              description={SD_LA_DESCRIPTION}
          />
          <StatRowItem
              label="Zone Judgement"
              rank={player.rank?.swingDecision}
              value={player.swingDecision}
              description={ZONE_JUDGEMENT_DESCRIPTION}
          />
          <StatRowItem
              label="Chase %"
              rank={player.rank?.chasePercent}
              value={`${player.chasePercent?.toFixed(1)}%`}
              description={CHASE_DESCRIPTION}
          />
          <StatRowItem
              label="Whiff %"
              rank={player.rank?.whiffPercent}
              value={`${player.whiff?.toFixed(1)}%`}
              description={WHIFF_DESCRIPTION}
          />
          <StatRowItem
              label="CSW %"
              rank={player.rank?.callStrikePercent}
              value={`${player.callStrikePercent?.toFixed(1)}%`}
              description={CSW_DESCRIPTION}
          />
        </Section>
      </PlayerStatsContainer>
  );
};

export default HitterStatsBar;

export const PlayerStatsContainer = styled.div`
  margin: 20px 0;
  padding: 0 0px;
`;

export const Section = styled.div`
  margin: 10px 0;
  width: 100%;
`;

export const WarningMessage = styled.div`
  color: red;
  font-weight: bold;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Fira Code";
`;
