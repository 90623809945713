import React from 'react';
import {HitterStatRes} from '../../type/res/HitterStatRes';
import CommonSort from "./CommonSort";

type HitterStatColumn = {
  header: string;
  field: keyof HitterStatRes | 'index';
};

interface HitterSortProps {
  column: HitterStatColumn;
  sortField: keyof HitterStatRes;
  sortOrder: 'asc' | 'desc';
  handleSort: (field: keyof HitterStatRes) => void;
}

const SORTABLE_COLUMNS: (keyof HitterStatRes)[] = [
  'avg',
  'obp',
  'slg',
  'ops',
  'woba',
  'plateAppearance',
  'expectedWobaCon',
  'expectedWoba',
  'expectedBattingAverage',
  'exitVelocity',
  'sweetSpotPercent',
  'hardHitPercent',
  'barrelPercent',
  'barrelByPaPercent',
  'solidLaunchAngle',
  'whiff',
  'chasePercent',
  'swingDecision',
  'callStrikePercent',
  'icrPercent',
  'ipaPercent'
];

const HitterSort = ({ column, sortField, sortOrder, handleSort}: HitterSortProps) => (
  <CommonSort
    column={column}
    sortField={sortField}
    sortOrder={sortOrder}
    handleSort={(field) => SORTABLE_COLUMNS.includes(field) && handleSort(field)}
  />
);

export default HitterSort;
